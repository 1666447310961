import React from "react";
// import logo from "./logo.svg";
import "./App.css";

import { useKeycloak } from "@react-keycloak/web";
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import EditProfile from './pages/EditProfile';
import AddCompany from './pages/Companies/AddCompany';
import CompanyListPage from './pages/Companies/CompanyListPage';
import AddRole from './pages/Roles/AddRole';
import RolesListPage from './pages/Roles/RolesListPage';
import AddBranch from './pages/Branches/AddBranch';
import BranchListPage from './pages/Branches/BranchListPage';
import AddUser from './pages/Users/AddUser';
import UserListPage from './pages/Users/UserListPage';
import AddAvatar from './pages/Avatars/AddAvatar';
import AvatarListPage from './pages/Avatars/AvatarListPage';
import AddGarment from './pages/Garments/AddGarment';
import GarmentListPage from './pages/Garments/GarmentListPage';
import AddScene from './pages/Scenes/AddScene';
import SceneListPage from './pages/Scenes/SceneListPage';
import { ProtectedRoute } from './utils';

function App() {
  const { keycloak, initialized } = useKeycloak();

  console.log(keycloak);

  if (!initialized) {
    return <div>Loading...</div>
  }

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <ProtectedRoute path="/myProfile" component={EditProfile} />
        <ProtectedRoute path="/addCompany" component={AddCompany} />
        <ProtectedRoute path="/companies" component={CompanyListPage} />
        <ProtectedRoute path="/editCompany/:id" component={AddCompany} />
        <ProtectedRoute path="/addRole" component={AddRole} />
        <ProtectedRoute path="/editRole/:id" component={AddRole} />
        <ProtectedRoute path="/roles" component={RolesListPage} />
        <ProtectedRoute path="/addBranch" component={AddBranch} />
        <ProtectedRoute path="/editBranch/:id" component={AddBranch} />
        <ProtectedRoute path="/branches" component={BranchListPage} />
        <ProtectedRoute path="/addUser" component={AddUser} />
        <ProtectedRoute path="/editUser/:id" component={AddUser} />
        <ProtectedRoute path="/users" component={UserListPage} />
        <ProtectedRoute path="/addAvatar" component={AddAvatar} />
        <ProtectedRoute path="/avatars" component={AvatarListPage} />
        <ProtectedRoute path="/editAvatar/:id" component={AddAvatar} />
        <ProtectedRoute path="/addGarment" component={AddGarment} />
        <ProtectedRoute path="/garments" component={GarmentListPage} />
        <ProtectedRoute path="/editGarment/:id" component={AddGarment} />
        <ProtectedRoute path="/scenes" component={SceneListPage} />
        <ProtectedRoute path="/addScene" component={AddScene} />
        <ProtectedRoute path="/editScene/:id" component={AddScene} />
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </Router>
  );
}

export default App;
