import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import _ from "lodash";
import { createBranch, updateBranch, fetchBranch } from "../../actions/branch";
import { listCompanies } from "../../actions/company";
import { getCompanies } from "../../selectors/company";
import { getBranchDetails } from "../../selectors/branch";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import branch from "../../reducers/branch";
import { generateTreeStructure } from "../../utils/companies";
import { listRoles } from "../../actions/role";
import { getRoles } from "../../selectors/role";

const BranchSchema = Yup.object().shape({
  name: Yup.string().required("This field is mandatory"),
  address: Yup.string().required("This field is mandatory"),
  telNumber: Yup.string().required(),
  email: Yup.string().required("This field is mandatory"),
  // isRoot: Yup.boolean().required("This field is mandatory"),
});

const startValues = {
  name: "",
  address: "",
  telNumber: "",
  email: "",
  company: "",
  isRoot: false,
};

const AddBranch = ({
  listCompanies,
  createBranch,
  updateBranch,
  companiesList,
  fetchBranch,
  branchDetails,
  listRoles,
  rolesList,
}) => {
  let { id } = useParams();
  const [initialValues, setInitialValues] = useState(startValues);

  useEffect(() => {
    listCompanies({ max: 10000 });
    listRoles();
    if (id) {
      fetchBranch(id);
    }
  }, []);

  const {
    branchData,
    isFetching: branchDataIsFetching,
    isFetched: branchDataIsFetched,
  } = branchDetails;

  let rolesListData = rolesList.listData;
  rolesListData = _.filter(rolesListData, { description: "company" });

  const history = useHistory();
  const companiesListData = companiesList.listData;
  const flattenedTreeCompanies = generateTreeStructure(companiesListData, 0);

  const formSubmit = async (values) => {
    try {
      let branch = null;
      const company = _.find(
        companiesListData,
        (x) => _.get(x, "subGroups[0].id") == values.company
      );
      console.log(company);
      if (id) {
        branch = await updateBranch(id, values);
      } else {
        branch = await createBranch({
          name: values.name,
          attributes: {
            companyId: [values.company],
            email: [values.email],
            address: [values.address],
            telNumber: [values.telNumber],
            roleId: company.realmRoles,
          },
          // isRoot: values.isRoot === "true",
          // company: {
          //   id: values.company,
          // },
        });
      }
      history.push("/companies");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id && branchData) {
      setInitialValues({
        name: branchData.name || "",
        address: _.get(branchData, "attribures.address", []).join(""),
        telNumber: _.get(branchData, "attribures.telNumber", []).join(""),
        email: _.get(branchData, "attribures.email", []).join(""),
        company: _.get(branchData, "attributes.companyId", []).join(""),
        // isRoot: branchData.isRoot ? 'true' : 'false',
      });
    }
  }, [id, branchData]);

  return (
    <>
      <Header></Header>
      <div className="flex flex-row bg-whiteSmoke font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1 mb-20">
          <div className="text-left text-2xl leading-tight p-8 font-poppinsRegular">
            Branches
          </div>
          <div className="max-w-md mx-auto">
            <div className="text-center text-2xl leading-tight uppercase mt-10 mb-10">
              {id ? 'Edit' : 'Add a new'} branch
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={BranchSchema}
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={formSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="">
                    <label for="name" className="text-gray-400 text-sm">
                      Branch name
                    </label>
                    <Field
                      name="name"
                      type="text"
                      className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    ></Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label for="address" className="text-gray-400 text-sm">
                      Branch address
                    </label>
                    <Field
                      name="address"
                      type="text"
                      className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    ></Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="address" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label for="email" className="text-gray-400 text-sm">
                      Branch email
                    </label>
                    <Field
                      name="email"
                      type="text"
                      className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    ></Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label for="telNumber" className="text-gray-400 text-sm">
                      Branch telephone number
                    </label>
                    <Field
                      name="telNumber"
                      type="text"
                      className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    ></Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label className="text-gray-400 text-sm" for="branch">
                      Company
                      <Field
                        component="select"
                        id="company"
                        name="company"
                        multiple={false}
                        className="rounded flex-1 block w-52 py-2 px-3 border border-white bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      >
                        <option></option>
                        {flattenedTreeCompanies.map((company, idx) => {
                          return (
                            <option
                              key={company.id}
                              value={
                                _.find(
                                  company.subGroups,
                                  (x) => x.name === "branches"
                                ).id
                              }
                            >
                              {company.name}
                            </option>
                          );
                        })}
                      </Field>
                    </label>
                  </div>

                  {/* <div className="mt-5">
                  <label className="text-gray-400 text-sm" for="role">
                    Roles
                    <Field
                      component="select"
                      id="role"
                      name="role"
                      multiple={false}
                      className="rounded flex-1 block w-52 py-2 px-3 border border-white bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                    >
                      <option>Role</option>
                      {rolesListData.map((role, idx) => {
                        return (
                          <option
                            className="rounded text-gray"
                            key={role.id}
                            value={role.id}
                          >
                            {role.name}
                          </option>
                        );
                      })}
                    </Field>
                  </label>
                </div> */}
                  {/* <div className="mt-5"> */}
                  {/* <label for="isRoot" className="text-gray-400 text-sm">
                    Is Root Branch
                  </label>
                  <div className="flex items-center gap-8">
                    <div role="group" aria-labelledby="active">
                      <label className="inline-flex items-center">
                        <Field
                          type="radio"
                          name="isRoot"
                          className="h-5 w-5 text-red-600"
                          value="true"
                        />
                        <span className="ml-2 text-gray-400 text-sm">Yes</span>
                      </label>
                      <br />
                      <label className="inline-flex items-center">
                        <Field
                          type="radio"
                          name="isRoot"
                          className="h-5 w-5 text-red-600"
                          value="false"
                        />
                        <span className="ml-2 text-gray-400 text-sm">No</span>
                      </label>
                    </div>
                  </div>
                </div> */}
                  <div className="flex flex-row mt-10">
                    <Link to="/branches">
                      <button
                        type="button"
                        className="bg-transparent uppercase text-blue text-xl border-blue px-16 py-3 rounded border-2 mr-8"
                      >
                        Cancel
                      </button>
                    </Link>

                    <button
                      type="submit"
                      className="bg-blue uppercase text-white text-xl border-blue px-20 py-3 rounded border-2"
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  companiesList: getCompanies(state),
  branchDetails: getBranchDetails(state),
  rolesList: getRoles(state),
});

const mapDispatchToProps = {
  createBranch,
  updateBranch,
  fetchBranch,
  listCompanies,
  listRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBranch);
