import React, { useEffect, useMemo, useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useFormik,
  FormikProvider,
} from "formik";
import * as yup from "yup";
import _ from "lodash";
import { useHistory, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { createUser, updateUser, fetchUser } from "../../actions/user";
import { listRoles } from "../../actions/role";
import { listBranches } from "../../actions/branch";
import { listCompanies } from "../../actions/company";
import { getUserDetails } from "../../selectors/user";
import { getRoles } from "../../selectors/role";
import { getBranches } from "../../selectors/branch";
import { getCompanies } from "../../selectors/company";
import user from "../../reducers/user";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import keycloak from "../../keycloak";
import {
  findGroupByPath,
  generateBranchesTreeStructure,
  generateChildCompanyTreeStructure,
  generateTreeStructure,
} from "../../utils/companies";

const UserSchema = yup.object().shape({
  firstName: yup.string().required("This field is mandatory"),
  lastName: yup.string().required("This field is mandatory"),
  email: yup.string().required("This field is mandatory"),
  username: yup.string().required("This field is mandatory"),
  // telNumber: yup.string().required("This field is mandatory"),
  // attributes: Yup.object().shape({
  //   telNumber: Yup.string().required(),
  //   role: {
  //     id: Yup.string().required(),
  //   },
  //   branch: {
  //     id: Yup.string().required(),
  //   }
  // }),
});

const startValues = {
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  enabled: true,
  credentials: [
    {
      value: "",
      type: "password",
      temporary: "false",
    },
  ],
  attributes: {
    roleId: "",
    telNumber: "",
    branchId: "",
    companyId: "",
  },
};

const AddUser = ({
  createUser,
  updateUser,
  listRoles,
  listBranches,
  fetchUser,
  rolesList,
  branchesList,
  companiesList,
  listCompanies,
  userDetails,
}) => {
  const [initialValues, setInitialValues] = useState(startValues);
  let { id } = useParams();

  useEffect(() => {
    listRoles();
    listCompanies({ max: 10000, offset: 0 });
    if (id) {
      fetchUser(id);
    }
  }, []);

  const {
    userData,
    isFetching: userDataIsFetching,
    isFetched: userDataIsFetched,
  } = userDetails;

  const isSuperAdmin = useMemo(
    () =>
      _.intersection(keycloak.tokenParsed.realm_access.roles, ["super_admin"]),
    []
  );
  const isSellerAdmin = useMemo(
    () =>
      _.intersection(keycloak.tokenParsed.realm_access.roles, ["seller_admin"]),
    []
  );
  const isDealerAdmin = useMemo(
    () =>
      _.intersection(keycloak.tokenParsed.realm_access.roles, ["dealer_admin"]),
    []
  );
  const isBuyerAdmin = useMemo(
    () =>
      _.intersection(keycloak.tokenParsed.realm_access.roles, ["buyer_admin"]),
    []
  );

  const history = useHistory();
  const formSubmit = async (values) => {
    try {
      let user = null;
      const updatedData = {
        ...values,
        attributes: {
          ...values.attributes,
          roleId: [values.attributes.roleId],
          companyId: [values.attributes.companyId],
          branchId: [values.attributes.branchId],
        },
      };
      if (id) {
        user = await updateUser(id, updatedData);
      } else {
        user = await createUser(updatedData);
      }
      history.push("/users");
    } catch (e) {
      console.log(e);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: formSubmit,
  });

  let rolesListData = rolesList.listData;
  const companiesListData = companiesList.listData;

  let filteredCompanies = useMemo(() => {
    if (companiesListData) {
      if (isSuperAdmin) {
        return generateTreeStructure(companiesListData, 0);
      } else {
        return generateChildCompanyTreeStructure(
          companiesListData,
          keycloak.idTokenParsed.group_membership[0]
        );
      }
    }
    return [];
  }, [companiesListData, isSuperAdmin]);

  const companiesMap = useMemo(() => {
    const map = {};
    for (let i = 0; i < filteredCompanies.length; ++i) {
      map[filteredCompanies[i].id] = filteredCompanies[i];
    }
    return map;
  }, [filteredCompanies]);

  const selectedCompanyId = _.get(formik.values, "attributes.companyId", "");

  const branchesListData = useMemo(() => {
    if (selectedCompanyId && companiesListData) {
      return generateBranchesTreeStructure(
        companiesListData,
        `${companiesMap[selectedCompanyId].path}/branches`
      );
    }
    return [];
  }, [companiesListData, companiesMap, selectedCompanyId]);

  rolesListData = _.filter(rolesListData, { description: "user" });

  // const availableRoles = useMemo(() => {
  //   if (isSuperAdmin) {
  //     return rolesListData;
  //   }
  //   if (isSellerAdmin) {
  //     return _.filter(rolesListData, (role) => {
  //       return _.intersection(
  //         [role.name],
  //         ["seller_admin", "dealer_admin", "buyer_admin", "user"]
  //       ).length;
  //     });
  //   }
  //   if (isDealerAdmin) {
  //     return _.filter(rolesListData, (role) => {
  //       return _.intersection(
  //         [role.name],
  //         ["dealer_admin", "buyer_admin", "user"]
  //       ).length;
  //     });
  //   }
  //   if (isBuyerAdmin) {
  //     return _.filter(rolesListData, (role) => {
  //       return _.intersection([role.name], ["buyer_admin", "user"]).length;
  //     });
  //   }
  // }, [rolesListData, isSuperAdmin, isSellerAdmin, isDealerAdmin, isBuyerAdmin]);

  const availableCompanyRoles = useMemo(() => {
    const company = companiesMap[formik.values.attributes.companyId];
    if (!company) {
      return [];
    }
    const roles = _.filter(
      rolesListData,
      (role) =>
        role.name === "user" || role.name.startsWith(company.realmRoles[0])
    );
    return roles;
  }, [formik.values.attributes.companyId, companiesMap]);

  useEffect(() => {
    if (userData) {
      setInitialValues({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        username: userData.username || "",
        email: userData.email || "",
        attributes: {
          roleId: (_.get(userData, "attributes.roleId") || []).join("") || "",
          branchId:
            (_.get(userData, "attributes.branchId") || []).join("") || "",
          companyId:
            (_.get(userData, "attributes.companyId") || []).join("") || "",
          telNumber:
            (_.get(userData, "attributes.telNumber") || []).join("") || "",
        },
      });
    }
  }, [userData]);

  return (
    <>
      <Header></Header>
      <div className="flex flex-row bg-whiteSmoke font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1 mb-20">
          <div className="text-left text-2xl leading-tight p-8">Users</div>
          <div className="max-w-md mx-auto">
            <div className="text-center text-2xl leading-tight uppercase mt-5 mb-5">
              {id ? "Edit" : "Add a new"} user
            </div>
            <FormikProvider value={formik}>
              <Form>
                <div className="relative">
                  <label for="firstName" className="text-gray-400 text-sm">
                    First name
                  </label>
                  <Field
                    name="firstName"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                </div>
                <div className="mt-5">
                  <label for="lastName" className="text-gray-400 text-sm">
                    Last name
                  </label>
                  <Field
                    name="lastName"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                </div>
                <div className="mt-5">
                  <label for="email" className="text-gray-400 text-sm">
                    Email
                  </label>
                  <Field
                    name="email"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                </div>
                <div className="mt-5">
                  <label for="username" className="text-gray-400 text-sm">
                    Username
                  </label>
                  <Field
                    name="username"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                </div>
                <div className="mt-5">
                  <label
                    for="credentials[0].value"
                    className="text-gray-400 text-sm"
                  >
                    Password
                  </label>
                  <Field
                    name="credentials[0].value"
                    type="password"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Blank to leave unchanged"
                  ></Field>
                </div>
                <div className="mt-5">
                  <label
                    for="attributes.telNumber"
                    className="text-gray-400 text-sm"
                  >
                    Telephone number
                  </label>
                  <Field
                    name="attributes.telNumber"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                </div>
                <div className="mt-5">
                  <label
                    className="text-gray-400 text-sm"
                    for="attributes.companyId"
                  >
                    Company
                    <Field
                      component="select"
                      id="attributes.companyId"
                      name="attributes.companyId"
                      multiple={false}
                      className="rounded flex-1 block w-52 py-2 px-3 border border-white bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                    >
                      <option></option>
                      {filteredCompanies.map((company, idx) => {
                        return (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        );
                      })}
                    </Field>
                  </label>
                </div>
                <div className="mt-5">
                  <label
                    className="text-gray-400 text-sm"
                    for="attributes.branchId"
                  >
                    Branch
                    <Field
                      component="select"
                      id="attributes.branchId"
                      name="attributes.branchId"
                      multiple={false}
                      className="rounded flex-1 block w-52 py-2 px-3 border border-white bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                    >
                      <option></option>
                      {branchesListData.map((branch, idx) => {
                        return (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        );
                      })}
                    </Field>
                  </label>
                </div>
                <div className="mt-5">
                  <label
                    className="text-gray-400 text-sm"
                    for="attributes.roleId"
                  >
                    Role
                    <Field
                      component="select"
                      id="attributes.roleId"
                      name="attributes.roleId"
                      multiple={false}
                      className="rounded flex-1 block w-52 py-2 px-3 border border-white bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                    >
                      <option></option>
                      {availableCompanyRoles.map((role, idx) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.name.replace("_", " ")}
                          </option>
                        );
                      })}
                    </Field>
                  </label>
                </div>
                <div className="flex flex-row mt-10">
                  <Link to="/users">
                    <button
                      type="button"
                      className="bg-transparent uppercase text-blue text-xl border-blue px-16 py-3 rounded border-2 mr-8"
                    >
                      Cancel
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="bg-blue uppercase text-white text-xl border-blue px-20 py-3 rounded border-2"
                  >
                    Save
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  rolesList: getRoles(state),
  branchesList: getBranches(state),
  userDetails: getUserDetails(state),
  companiesList: getCompanies(state),
});

const mapDispatchToProps = {
  createUser,
  listRoles,
  fetchUser,
  updateUser,
  listBranches,
  listCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
