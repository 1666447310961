import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import _ from "lodash";
import { useParams, useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { createScene, updateScene, fetchScene } from "../../actions/scene";
import { getSceneDetails } from "../../selectors/scene";
import { listBranches } from "../../actions/branch";
import { getBranches } from "../../selectors/branch";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { generateChildCompanyTreeStructure, generateTreeStructure, getCompanyBranchTitle } from "../../utils/companies";
import keycloak from "../../keycloak";

const SceneSchema = yup.object().shape({
  name: yup.string().required("This field is mandatory"),
});

const startValues = {
  name: "",
  availableTo: [],
  active: false,
};

const AddScene = ({
  createScene,
  updateScene,
  fetchScene,
  listBranches,
  branchesList,
  sceneDetails,
}) => {
  let { id } = useParams();
  const [initialValues, setInitialValues] = useState(startValues);

  useEffect(() => {
    listBranches();
    if (id) {
      fetchScene(id);
    }
  }, []);

  const {
    sceneData,
    isFetching: sceneDataIsFetching,
    isFetched: sceneDataIsFetched,
  } = sceneDetails;

  const history = useHistory();

  const branchesListData = branchesList.listData;

  const isSuperAdmin = useMemo(
    () =>
      _.intersection(keycloak.tokenParsed.realm_access.roles, ["super_admin"]),
    []
  );

  let filteredCompanies = useMemo(() => {
    if (branchesListData && branchesListData.length) {
      if (isSuperAdmin) {
        return generateTreeStructure(branchesListData, 0);
      } else {
        return generateChildCompanyTreeStructure(
          branchesListData,
          keycloak.idTokenParsed.group_membership[0],
          true
        );
      }
    }
    return [];
  }, [branchesListData, isSuperAdmin]);

  const disregardedAvailableTo = useMemo(() => {
    return _.difference(
      initialValues.availableTo,
      filteredCompanies.map((x) => x.id)
    );
  }, [initialValues, filteredCompanies]);

  const formSubmit = async (values) => {
    try {
      let scene = null;
      values.availableTo = disregardedAvailableTo.concat(values.availableTo);
      if (id) {
        if (values.active === "true") {
          values.active = true;
        } else {
          values.active = false;
        }
        scene = await updateScene(id, values);
        history.push("/scenes");
      } else {
        if (values.active === "true") {
          values.active = true;
        } else {
          values.active = false;
        }
        scene = await createScene(values);
        history.push("/scenes");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id && sceneData) {
      console.log("id:", id);
      console.log("sceneData:", sceneData);
      setInitialValues({
        name: sceneData.name || "",
        availableTo: sceneData.availableTo || [],
        active: sceneData.active ? "true" : "false",
      });
    }
  }, [id, sceneData]);

  return (
    <>
      <Header></Header>
      <div className="flex flex-row bg-whiteSmoke font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1 mb-20">
          <div className="text-left text-2xl leading-tight p-8">Scenes</div>
          <div className="max-w-md mx-auto">
            <div className="text-center text-2xl leading-tight uppercase mt-10 mb-10">
              {id ? "Edit" : "Add a new"} scene
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={SceneSchema}
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={formSubmit}
            >
              <Form>
                <div className="relative">
                  <label for="name" className="text-gray-400 text-sm">
                    Scene name
                  </label>
                  <Field
                    name="name"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                  <div className="text-red text-center mb-5">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div className="mt-5">
                  <label className="text-gray-400 text-sm" for="availableTo">
                    Available To
                    <Field
                      component="select"
                      id="availableTo"
                      name="availableTo"
                      multiple={true}
                      className="rounded flex-1 block w-52 py-2 px-3 border border-white bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500 w-full"
                    >
                      {filteredCompanies
                        .filter((c) => c.name !== "branches")
                        .map((branch, idx) => {
                          return (
                            <option key={branch.id} value={branch.id}>
                              {getCompanyBranchTitle(branch)}
                            </option>
                          );
                        })}
                    </Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="availableTo" />
                    </div>
                  </label>
                </div>
                <div className="mt-5">
                  <label for="active" className="text-gray-400 text-sm">
                    Active
                  </label>
                  <div className="flex items-center gap-8">
                    <div role="group" aria-labelledby="active">
                      <label className="inline-flex items-center">
                        <Field
                          type="radio"
                          name="active"
                          className="h-5 w-5 text-red-600"
                          value="true"
                        />
                        <span className="ml-2 text-gray-400 text-sm">
                          Active
                        </span>
                      </label>
                      <br />
                      <label className="inline-flex items-center">
                        <Field
                          type="radio"
                          name="active"
                          className="h-5 w-5 text-red-600"
                          value="false"
                        />
                        <span className="ml-2 text-gray-400 text-sm">
                          Inactive
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row mt-10">
                  <Link to="/scenes">
                    <button
                      type="button"
                      className="bg-transparent uppercase text-blue text-xl border-blue px-16 py-3 rounded border-2 mr-8"
                    >
                      Cancel
                    </button>
                  </Link>

                  <button
                    type="submit"
                    className="bg-blue uppercase text-white text-xl border-blue px-20 py-3 rounded border-2"
                  >
                    Save
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sceneDetails: getSceneDetails(state),
  branchesList: getBranches(state),
});

const mapDispatchToProps = {
  createScene,
  updateScene,
  fetchScene,
  listBranches,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddScene);
