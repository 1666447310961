import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

// Assets
import edit from "../../img/edit.svg";
import X from "../../img/x.svg";
import sort from "../../img/blue-icons/sort.svg";

const GarmentList = ({
  results,
  garments,
  deleteGarment,
  branchesList,
  toggleSort,
  ...props
}) => {
  return (
    <div className="shadow rounded-md bg-white mt-7 p-8">
      <table className="min-w-full leading-normal">
        <thead>
          <tr>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              ID
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Thumbnail
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Name
              <button
                onClick={() => toggleSort("name")}
                className="focus:outline-none"
              >
                <img
                  src={sort}
                  className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                  alt="Sort button"
                />
              </button>
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Product Num.
              <button
                onClick={() => toggleSort("productNumber")}
                className="focus:outline-none"
              >
                <img
                  src={sort}
                  className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                  alt="Sort button"
                />
              </button>
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Available to
              <button
                onClick={() => toggleSort("availableTo")}
                className="focus:outline-none"
              >
                <img
                  src={sort}
                  className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                  alt="Sort button"
                />
              </button>
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Colors
              <button
                onClick={() => toggleSort("colors")}
                className="focus:outline-none"
              >
                <img
                  src={sort}
                  className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                  alt="Sort button"
                />
              </button>
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Category
              <button
                onClick={() => toggleSort("category")}
                className="focus:outline-none"
              >
                <img
                  src={sort}
                  className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                  alt="Sort button"
                />
              </button>
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Status
              <button
                onClick={() => toggleSort("status")}
                className="focus:outline-none"
              >
                <img
                  src={sort}
                  className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                  alt="Sort button"
                />
              </button>
            </th>
            <th
              scope="col-span"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {results.map((garment, idx) => {
            const branches = garment.availableTo.map((item, idx) => {
              return _.find(branchesList, { id: item });
            });
            return (
              <tr {...props}>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{idx + 1}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/productmng/uploads/${garment.imageUrl}`}
                    alt="Garment thumbnail"
                    className="w-32"
                  />
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {garment.name}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {garment.productNumber}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {branches.map((branch, idx) => {
                      return <p>{branch ? branch.name : ""}</p>;
                    })}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <div className="flex flex-row flex-wrap">
                    {garment.colors.map((color, idx) => {
                      return (
                        <div
                          className="w-4 h-4 rounded-full mr-3"
                          style={{ backgroundColor: color }}
                        ></div>
                      );
                    })}
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {garment.category}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {garment.status}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <div className="flex flex-row">
                    <div>
                      <Link to={`/editGarment/${garment.id}`}>
                        <img
                          src={edit}
                          className="bg-lightBlue rounded p-2 w-8 mr-5"
                          alt="Edit button"
                        />
                      </Link>
                    </div>
                    <button onClick={() => deleteGarment(garment.id)}>
                      <img
                        src={X}
                        alt="Delete button"
                        className="rounded border-2 border-gray-300 p-2 w-8 mr-5"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GarmentList;
