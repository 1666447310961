import getAxiosInstance from '../config/http';

export const CREATE_SCENE_REQUEST = 'SCENE@CREATE_SCENE_REQUEST';
export const CREATE_SCENE_SUCCESS = 'SCENE@CREATE_SCENE_SUCCESS';
export const CREATE_SCENE_FAILURE = 'SCENE@CREATE_SCENE_FAILURE';

export const createScene = sceneData => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_SCENE_REQUEST,
  });
  try{
    const { data } = await http.post('/productmng/scene', sceneData);
    dispatch({
      type: CREATE_SCENE_SUCCESS,
      payload: {
        sceneData: data,
      },
    });
    return data;
  }
  catch(e){
    dispatch({
      type: CREATE_SCENE_FAILURE,
    });
    throw e;
  }
};

export const LIST_SCENE_REQUEST = 'SCENE@LIST_SCENE_REQUEST';
export const LIST_SCENE_SUCCESS = 'SCENE@LIST_SCENE_SUCCESS';
export const LIST_SCENE_FAILURE = 'SCENE@LIST_SCENE_FAILURE';

export const listScenes = params => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_SCENE_REQUEST,
  });
  try{
    const sceneData = await http.get('/productmng/scene/list/all', {params});
    dispatch({
      type: LIST_SCENE_SUCCESS,
      payload: {
        listData: sceneData.data.results,
        total: sceneData.data.total,
      }
    });
  }catch(e){
    dispatch({
      type: LIST_SCENE_FAILURE,
    });
  }
};

export const FETCH_SCENE_REQUEST = 'SCENE@FETCH_SCENE_REQUEST';
export const FETCH_SCENE_SUCCESS = 'SCENE@FETCH_SCENE_SUCCESS';
export const FETCH_SCENE_FAILURE = 'SCENE@FETCH_SCENE_FAILURE';

export const fetchScene = sceneId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_SCENE_REQUEST,
  });
  try{
    const sceneData = await http.get(`/productmng/scene/${sceneId}`);
    dispatch({
      type: FETCH_SCENE_SUCCESS,
      payload: {
        sceneData: sceneData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_SCENE_FAILURE,
    });
  };
};

export const UPDATE_SCENE_REQUEST = 'SCENE@UPDATE_SCENE_REQUEST';
export const UPDATE_SCENE_SUCCESS = 'SCENE@UPDATE_SCENE_SUCCESS';
export const UPDATE_SCENE_FAILURE = 'SCENE@UPDATE_SCENE_FAILURE';

export const updateScene = (id, sceneData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_SCENE_REQUEST,
  });
  try{
    const data = await http.put(`/productmng/scene/${id}`, sceneData);
    dispatch({
      type: UPDATE_SCENE_SUCCESS,
      payload: {
        sceneData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_SCENE_FAILURE,
    });
  };
};

export const DELETE_SCENE_REQUEST = 'SCENE@DELETE_SCENE_REQUEST';
export const DELETE_SCENE_SUCCESS = 'SCENE@DELETE_SCENE_SUCCESS';
export const DELETE_SCENE_FAILURE = 'SCENE@DELETE_SCENE_FAILURE';

export const deleteScene = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_SCENE_REQUEST,
  });
  try{
    const data = await http.delete(`/productmng/scene/${id}`);
    dispatch({
      type: DELETE_SCENE_SUCCESS,
      payload: {
        sceneId: id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_SCENE_FAILURE,
    });
  }
};
