import React from "react";
import { Link } from "react-router-dom";

// Assets
import edit from "../../img/edit.svg";
import X from "../../img/x.svg";
import sort from "../../img/blue-icons/sort.svg";

const BranchList = ({
  results,
  branches,
  deleteBranch,
  toggleSort,
  ...props
}) => {
  return (
    <>
      <div className="shadow rounded-md bg-white mt-10 p-8">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Branch Name
                <button
                  onClick={() => toggleSort("name")}
                  className="focus:outline-none"
                >
                  <img
                    src={sort}
                    className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                    alt="Sort button"
                  />
                </button>
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Subgroups
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Path
              </th>
              {/* <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Address
              </th>
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Email
              </th>
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Phone Number
              </th> */}
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Is Root Branch
              </th>
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {results.map((branch, idx) => {
              return (
                <tr {...props}>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {idx + 1}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {branch.name}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {branch.subGroups &&
                        branch.subGroups.map((subgroup, idx) => {
                          return <p>{subgroup ? subgroup.name : ""}</p>;
                        })}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {branch.path}
                    </p>
                  </td>
                  {/* <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {branch.address}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {branch.email}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {branch.telNumber}
                    </p>
                  </td> */}
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {branch.isRoot === true ? "True" : "False"}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <div className="flex flex-row">
                      <div>
                        <Link to={`/editBranch/${branch.id}`}>
                          <img
                            src={edit}
                            className="bg-lightBlue rounded p-2 w-8 mr-5"
                            alt="Edit button"
                          />
                        </Link>
                      </div>
                      <button onClick={() => deleteBranch(branch.id)}>
                        <img
                          src={X}
                          alt="Delete button"
                          className="rounded border-2 border-gray-300 p-2 w-8 mr-5"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BranchList;
