import { combineReducers } from "redux";

import company from './company';
import role from './role';
import branch from './branch';
import user from './user';
import garment from './garment';
import avatar from './avatar';
import scene from './scene';

const rootReducer = combineReducers({company, role, branch, user, garment, avatar, scene});

export default rootReducer;
