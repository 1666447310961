import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as yup from "yup";
import _ from "lodash";
import { useParams, useHistory, Link } from "react-router-dom";
import keycloak from "../../keycloak";
import { connect } from "react-redux";
import {
  createCompany,
  updateCompany,
  fetchCompany,
  listCompanies,
} from "../../actions/company";
import { listRoles } from "../../actions/role";
import { getRoles } from "../../selectors/role";
import { getCompanies } from "../../selectors/company";
import { getCompanyDetails } from "../../selectors/company";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import {
  generateTreeStructure,
  generateChildCompanyTreeStructure,
  generateChildRoles,
  generateParentRoles,
} from "../../utils/companies";

const CompanySchema = yup.object().shape({
  name: yup.string().required("This field is mandatory"),
  address: yup.string().required("This field is mandatory"),
  VAT: yup.string().required("This field is mandatory"),
  telNumber: yup.string(),
  email: yup.string().required("This field is mandatory"),
});

const startValues = {
  name: "",
  address: "",
  VAT: 0,
  telNumber: "",
  email: "",
  role: "",
  createdBy: "",
};

const FormObserver = ({onChange}) => {
  const { values } = useFormikContext();
  useEffect(() => {
    if (onChange) {
      onChange(values);
    }
  }, [onChange, values]);
  return <></>;
};

const AddCompany = ({
  listRoles,
  listCompanies,
  rolesList,
  companiesList,
  createCompany,
  fetchCompany,
  updateCompany,
  companyDetails,
}) => {
  const [initialValues, setInitialValues] = useState(startValues);
  let { id } = useParams();

  const [vals, setVals] = useState({});

  useEffect(() => {
    listRoles();
    listCompanies({ max: 10000 });
    if (id) {
      fetchCompany(id);
    }
  }, [fetchCompany, listCompanies, listRoles, id]);
  const isSuperAdmin =
    _.indexOf(keycloak.tokenParsed.realm_access.roles, "super_admin") >= 0;

  const {
    companyData,
    // isFetching: companyDataIsFetching,
    // isFetched: companyDataIsFetched,
  } = companyDetails;

  const history = useHistory();

  const rolesListData = useMemo(
    () => _.filter(rolesList.listData || [], { description: "company" }),
    [rolesList]
  );

  const sellerRole = useMemo(() => {
    return _.find(rolesListData, { name: "seller" });
  }, [rolesListData]);

  const formSubmit = async (values) => {
    try {
      let company = null;
      const updatedData = {
        name: values.name,
        attributes: {
          address: [values.address],
          VAT: [values.VAT],
          email: [values.email],
          telNumber: [values.telNumber],
          roleId: [values.role],
          invitedBy: [values.invitedBy],
        },
      };
      if (values.role === sellerRole.id) {
        delete updatedData.attributes.invitedBy;
      }
      if (id) {
        company = await updateCompany(id, updatedData);
      } else {
        company = await createCompany(updatedData);
      }
      history.push("/companies");
    } catch (e) {
      console.log(e);
      alert("Something went wrong, please try again");
    }
  };
  let companiesListData = companiesList.listData;

  const selectedRole = useMemo(() => _.find(rolesListData, { id: vals.role }), [rolesListData, vals]) ;
  // console.log(selectedRole);

  let filteredCompanies = useMemo(() => {
    if (companiesListData) {
      if (isSuperAdmin) {
        const parentRoles = generateParentRoles(selectedRole ? selectedRole.name : 'seller', false, 1);
        return generateTreeStructure(
          companiesListData,
          0,
          [],
          false,
          parentRoles
        );
      } else {
        return generateChildCompanyTreeStructure(
          companiesListData,
          keycloak.idTokenParsed.group_membership[0]
        );
      }
    }
    return [];
  }, [companiesListData, selectedRole, isSuperAdmin]);

  useEffect(() => {
    if (id && companyData) {
      const roleId = _.get(companyData, "subGroups[0].attributes.roleId[0]");
      setInitialValues({
        name: companyData.name || "",
        address: companyData.attributes.address[0] || "",
        VAT: companyData.attributes.VAT[0] || 0,
        telNumber: companyData.attributes.telNumber[0] || "",
        email: companyData.attributes.email[0] || "",
        role: roleId || "",
        // invitedBy:
      });
    } else {
      if (filteredCompanies && filteredCompanies.length) {
        setInitialValues({
          invitedBy: filteredCompanies[0].id,
        });
      }
    }
  }, [id, companyData]);

  const formOnChange = useCallback((values) => {
    setVals(values);
  }, []);

  return (
    <>
      <Header></Header>
      <div className="flex flex-row bg-whiteSmoke font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1 mb-20">
          <div className="text-left text-2xl leading-tight p-8">Companies</div>
          <div className="max-w-md mx-auto">
            <div className="text-center text-2xl uppercase mb-10">
              {id ? "Edit" : "Add a new"} company
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={CompanySchema}
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={formSubmit}
            >
              {({ values }) => (
                <Form>
                  <FormObserver onChange={formOnChange} />
                  <div className="relative">
                    <label for="name" className="text-gray-400 text-sm">
                      Company name
                    </label>
                    <Field
                      name="name"
                      type="text"
                      className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    ></Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label for="address" className="text-gray-400 text-sm">
                      Company address
                    </label>
                    <Field
                      name="address"
                      type="text"
                      className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    ></Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="address" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label for="VAT" className="text-gray-400 text-sm">
                      VAT
                    </label>
                    <Field
                      name="VAT"
                      className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    ></Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="VAT" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label for="telNumber" className="text-gray-400 text-sm">
                      Telephone number
                    </label>
                    <Field
                      name="telNumber"
                      type="text"
                      className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    ></Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="telNumber" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label for="email" className="text-gray-400 text-sm">
                      Email
                    </label>
                    <Field
                      name="email"
                      type="text"
                      className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    ></Field>
                    <div className="text-red text-center mb-5">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label className="text-gray-400 text-sm" for="role">
                      Roles
                      <Field
                        component="select"
                        id="role"
                        name="role"
                        multiple={false}
                        className="rounded flex-1 block w-52 py-2 px-3 border border-white bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      >
                        <option>Role</option>
                        {rolesListData.map((role, idx) => {
                          return (
                            <option
                              className="rounded text-gray"
                              key={role.id}
                              value={role.id}
                            >
                              {role.name}
                            </option>
                          );
                        })}
                      </Field>
                    </label>
                  </div>
                  {isSuperAdmin && sellerRole && values.role !== sellerRole.id && (
                    <div className="mt-5">
                      <label className="text-gray-400 text-sm" for="role">
                        Invited by
                        <Field
                          component="select"
                          id="invitedBy"
                          name="invitedBy"
                          multiple={false}
                          className="rounded flex-1 block w-52 py-2 px-3 border border-white bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                        >
                          <option value={null}></option>
                          {filteredCompanies.map((company, idx) => {
                            return (
                              <option
                                className="rounded text-gray"
                                key={company.id}
                                value={company.id}
                              >
                                {company.name}
                              </option>
                            );
                          })}
                        </Field>
                      </label>
                    </div>
                  )}
                  {/* <div className="mt-5">
                          <Field
                            component="select"
                            id="branchId"
                            name="branchId"
                            multiple={false}
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke text-gray-700 w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          >
                            <option>Branch</option>
                            {branchesListData.map((branch, idx) => {
                              return (
                                <option
                                  className="rounded text-gray"
                                  key={branch.id}
                                  value={branch.id}
                                >
                                  {branch.name}
                                </option>
                              );
                            })}
                          </Field>
                        </div> */}
                  <div className="flex flex-row mt-10">
                    <Link to="/companies">
                      <button
                        type="button"
                        className="bg-transparent uppercase text-blue text-xl border-blue px-16 py-3 rounded border-2 mr-8"
                      >
                        Cancel
                      </button>
                    </Link>

                    <button
                      type="submit"
                      className="bg-blue uppercase text-white text-xl border-blue px-20 py-3 rounded border-2"
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  rolesList: getRoles(state),
  companiesList: getCompanies(state),
  companyDetails: getCompanyDetails(state),
});

const mapDispatchToProps = {
  createCompany,
  listRoles,
  listCompanies,
  fetchCompany,
  updateCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);
