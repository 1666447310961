import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: "https://texet-dev.dark-1.com/keycloak/auth",
  realm: "sols-vr",
  clientId: "user-mng-web"
};

const keycloak = new Keycloak(keycloakConfig);
export default keycloak;
