import React from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";

//Assets
import roles from "../img/gray-icons/role.svg";
import company from "../img/gray-icons/company.svg";
import branch from "../img/gray-icons/branch.svg";
import user from "../img/gray-icons/user.svg";
import avatar from "../img/gray-icons/avatar.svg";
import garment from "../img/gray-icons/garment.svg";

import activeRole from "../img/lightBlue-icons/role.svg";
import activeCompany from "../img/lightBlue-icons/company.svg";
import activeBranch from "../img/lightBlue-icons/branch.svg";
import activeUser from "../img/lightBlue-icons/user.svg";
import activeAvatar from "../img/lightBlue-icons/avatar.svg";
import activeGarment from "../img/lightBlue-icons/garment.svg";
import keycloak from "../keycloak";

const items = [
  // {
  //   title: "Roles",
  //   path: "/roles",
  //   icon: roles,
  //   activeIcon: activeRole,
  // },
  {
    title: "Companies",
    path: "/companies",
    icon: company,
    activeIcon: activeCompany,
    allowedRoles: ["super_admin", "dealer_admin", "seller_admin"],
  },
  // {
  //   title: "Branches",
  //   path: "/branches",
  //   icon: branch,
  //   activeIcon: activeBranch,
  // },
  {
    title: "Users",
    path: "/users",
    icon: user,
    activeIcon: activeUser,
    allowedRoles: ["super_admin", "dealer_admin", "seller_admin"],
  },
  {
    title: "Avatars",
    path: "/avatars",
    icon: avatar,
    activeIcon: activeAvatar,
  },
  {
    title: "Garments",
    path: "/garments",
    icon: garment,
    activeIcon: activeGarment,
  },
  {
    title: "Scenes",
    path: "/scenes",
    icon: roles,
    activeIcon: activeRole,
  },
];

const Sidebar = () => {
  const location = useLocation();
  return (
    <div className="bg-white">
      <div className="flex flex-col sm:flex-row">
        <div className="w-72 h-screen">
          {items.map((item, idx) => {
            const isAllowed =
              !item.allowedRoles ||
              _.intersection(
                item.allowedRoles,
                keycloak.tokenParsed.realm_access.roles
              ).length > 0;
            if (!isAllowed) {
              return <></>;
            }
            const active = location.pathname === item.path;
            return (
              <div className="ml-12">
                <Link
                  className="text-gray-600 flex items-center p-2 my-6 transition-colors hover:text-blue font-bold justify-start"
                  to={item.path}
                >
                  <img
                    src={active ? item.activeIcon : item.icon}
                    className="mr-4 w-8"
                    alt="Menu icon"
                  />
                  <span
                    className={`mx-4 text-md font-normal uppercase focus:text-lightBlue outline-none ${
                      active ? "text-blue font-bold" : ""
                    }`}
                  >
                    {item.title}
                  </span>
                </Link>
                <p className="text-gray-300 border-b-2 pb-2 border-gray-100 mb-4 text-md font-normal mr-10"></p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
