import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import jwt_decode from "jwt-decode";


import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Provider } from "react-redux";

import keycloak from "./keycloak";
import { setAccessToken } from "./config/http";
import createStore from './store';

const store = createStore();

const eventLogger = (event, error) => {
  console.log("onKeycloakEvent", event, error);
};

const tokenLogger = async (tokens) => {
  setAccessToken(tokens.token);
};


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={eventLogger}
        onTokens={tokenLogger}
      >
        <App />
      </ReactKeycloakProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
