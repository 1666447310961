import _remove from 'lodash/remove';
import * as GarmentActions from '../actions/garment';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  garmentsList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  garmentDetails: {
    isFetching: false,
    isFetched: false,
    modelData: null,
  },
};

const setGarmentListFetching = fetching => state => ({
  ...state,
  garmentsList: {
    ...state.garmentsList,
    isFetching: fetching,
  },
});

const setGarmentList = (state, { payload: { listData, total } }) => ({
  ...state,
  garmentsList: {
    ...state.garmentsList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setGarmentFetching = fetching => state => ({
  ...state,
  garmentDetails: {
    ...state.garmentDetails,
    isFetching: fetching,
  },
});

const setGarment = (state, { payload: { garmentData } }) => ({
  ...state,
  garmentDetails: {
    ...state.garmentDetails,
    garmentData,
    isFetching: false,
    isFetched: true,
  },
});

const setGarmentDeleted = (state, { payload: { garmentId } }) => {
  const newListData = state.garmentsList.listData;
  _remove(newListData, { id: garmentId });

  const newState = {
    ...state,
    garmentsList: {
      ...state.garmentsList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [GarmentActions.LIST_GARMENT_REQUEST]: setGarmentListFetching(true),
  [GarmentActions.LIST_GARMENT_SUCCESS]: setGarmentList,
  [GarmentActions.LIST_GARMENT_FAILURE]: setGarmentListFetching(false),
  [GarmentActions.FETCH_GARMENT_REQUEST]: setGarmentFetching(true),
  [GarmentActions.FETCH_GARMENT_SUCCESS]: setGarment,
  [GarmentActions.FETCH_GARMENT_FAILURE]: setGarmentFetching(false),
  [GarmentActions.DELETE_GARMENT_SUCCESS]: setGarmentDeleted,
};

export default reducerWithActionMap(actionMap, initialState);


