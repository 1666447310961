import getAxiosInstance from '../config/http';

export const CREATE_USER_REQUEST = 'USER@CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'USER@CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'USER@CREATE_USER_FAILURE';

export const createUser = (userData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_USER_REQUEST,
  });
  try{
    const {data} = await http.post(`/usermng/user`, userData);
    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: {
        userData: data,
      },
    });
    return data;
  }catch(e){
    console.log(e);
    dispatch({
      type: CREATE_USER_FAILURE,
    });
    throw e;
  }
};

export const LIST_USER_REQUEST = 'USER@LIST_USER__REQUEST';
export const LIST_USER_SUCCESS = 'USER@LIST_USER_SUCCESS';
export const LIST_USER_FAILURE = 'USER@LIST_USER_FAILURE';

export const listUsers = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_USER_REQUEST,
  });
  try{
    const userData = await http.get(`/usermng/user`, {params});
    dispatch({
      type: LIST_USER_SUCCESS,
      payload: {
        listData: userData.data,
        // total: userData.data.total,
      }
    });
  } catch(e){
    dispatch({
      type: LIST_USER_FAILURE,
    });
    throw e;
  };
};

export const FETCH_USER_REQUEST = 'USER@FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'USER@FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'USER@FETCH_USER_FAILURE';

export const fetchUser = userId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_USER_REQUEST,
  });
  try{
    const userData = await http.get(`/usermng/user/${userId}`);
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: {
        userData: userData.data,
      },
    });
  } catch(e){
    dispatch({
      type: FETCH_USER_FAILURE,
    });
    throw e;
  }
};

export const UPDATE_USER_REQUEST = 'USER@UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'USER@UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'USER@UPDATE_USER_FAILURE';

export const updateUser = (id, userData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try{
    const data = await http.put(`/usermng/user/${id}`, userData);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: {
        userData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_USER_FAILURE,
    });
    throw e;
  }
};

export const DELETE_USER_REQUEST = 'USER@DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'USER@DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'USER@DELETE_USER_FAILURE';

export const deleteUser = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_USER_REQUEST,
  });
  try{
    const {data} = await http.delete(`/usermng/user/${id}`);
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: {
        userId: id,
      }
    });
    return data;
  }catch(e){
    dispatch({
      type: DELETE_USER_FAILURE,
    });
    throw e;
  }
};
