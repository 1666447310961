import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

// Assets
import edit from "../../img/edit.svg";
import X from "../../img/x.svg";
import sort from "../../img/blue-icons/sort.svg";

const SceneList = ({
  results,
  scenes,
  deleteScene,
  branchesList,
  toggleSort,
  ...props
}) => {
  return (
    <div className="shadow rounded-md bg-white mt-7 p-8">
      <table className="min-w-full leading-normal">
        <thead>
          <tr>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              ID
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Name
              <button
                onClick={() => toggleSort("name")}
                className="focus:outline-none"
              >
                <img
                  src={sort}
                  className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                  alt="Sort button"
                />
              </button>
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Available to
              <button
                onClick={() => toggleSort("availableTo")}
                className="focus:outline-none"
              >
                <img
                  src={sort}
                  className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                  alt="Sort button"
                />
              </button>
            </th>
            <th
              scope="col"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Active
              <button
                onClick={() => toggleSort("active")}
                className="focus:outline-none"
              >
                <img
                  src={sort}
                  className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                  alt="Sort button"
                />
              </button>
            </th>
            <th
              scope="col-span"
              className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {results.map((scene, idx) => {
            const branches = scene.availableTo.map((item, idx) => {
              return _.find(branchesList, { id: item });
            });
            return (
              <tr {...props}>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{idx + 1}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {scene.name}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {branches.map((branch, idx) => {
                      return <p>{branch ? branch.name : ""}</p>;
                    })}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {scene.active === true ? "True" : "False"}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <div className="flex flex-row">
                    <div>
                      <Link to={`/editScene/${scene.id}`}>
                        <img
                          src={edit}
                          className="bg-lightBlue rounded p-2 w-8 mr-5"
                          alt="Edit button"
                        />
                      </Link>
                    </div>
                    <button onClick={() => deleteScene(scene.id)}>
                      <img
                        src={X}
                        alt="Delete button"
                        className="rounded border-2 border-gray-300 p-2 w-8 mr-5 focus:outline-none"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SceneList;
