import React, {useState, useEffect} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import BgImage from "../components/BgImage";
import { listUsers, fetchUser, updateUser } from "../actions/user";

const startValues = {
  name: "",
  roleType: "",
};

const EditProfile = ({ listUsers, fetchUser, updateUser }) => {
  const [initialValues, setInitialValues] = useState(startValues);

  let { id } = useParams();
  console.log('id:');
  console.log(id);
  const formSubmit = async (values) => {
    console.log(values);
  };

  useEffect(() => {
    listUsers();
  }, [])

  return (
    <>
     <Header></Header>
      <div className="flex flex-row bg-whiteSmoke font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1">
          <div className="text-left text-2xl leading-tight p-10">Profile</div>
          <div className="max-w-md mx-auto">
            <div className="text-center text-2xl uppercase mt-5 mb-10">
              Edit profile
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validateOnChange={false}
              onSubmit={formSubmit}
            >
              <Form>
                <div>
                  <label for="firstName" className="text-gray-400 text-sm">
                    First Name
                  </label>
                  <Field
                    name="firstName"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="lastName" />
                  </div>
                </div>
                <div className="mt-5">
                  <label for="lastName" className="text-gray-400 text-sm">
                   Last name
                  </label>
                  <Field
                    name="lastName"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="lastName" />
                  </div>
                </div>
                <div className="mt-5">
                  <label for="email" className="text-gray-400 text-sm">
                   E-mail
                  </label>
                  <Field
                    name="email"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="email" />
                  </div>
                </div>
                <div className="mt-5">
                  <label for="telNumber" className="text-gray-400 text-sm">
                   Phone number
                  </label>
                  <Field
                    name="telNumber"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="telNumber" />
                  </div>
                </div>
                <div className="flex flex-row">
                  <Link to="/dashboard">
                    <button
                      type="button"
                      className="bg-transparent uppercase text-blue text-xl border-blue px-16 py-3 rounded border-2 mr-8"
                    >
                      Cancel
                    </button>
                  </Link>

                  <button
                    type="submit"
                    className="bg-blue uppercase text-white text-xl border-blue px-20 py-3 rounded border-2"
                  >
                    Save
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = { listUsers, fetchUser, updateUser };
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
