import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { createRole, fetchRole, updateRole } from "../../actions/role";
import { getRoleDetails } from "../../selectors/role";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";

const RoleShema = yup.object().shape({
  name: yup.string().required("This field is mandatory"),
  roleType: yup.string().required("This field is mandatory"),
});

const startValues = {
  name: "",
  roleType: "",
};

const AddRole = ({ createRole, updateRole, roleDetails, fetchRole }) => {
  const {
    roleData,
    isFetching: roleDataIsFetching,
    isFetched: roleDataIsFetched,
  } = roleDetails;
  let { id } = useParams();
  const [initialValues, setInitialValues] = useState(startValues);

  useEffect(() => {
    fetchRole(id);
  }, []);

  const history = useHistory();
  const formSubmit = async (values) => {
    try {
      let role = null;
      if (id) {
        role = await updateRole(id, values);
        alert("The role is successfully updated");
        history.push("/roles");
      } else {
        role = await createRole(values);
        alert("The role is successfully created");
        history.push("/roles");
      }
    } catch (e) {
      console.log(e);
      alert("Something went wrong. Please try again");
    }
  };

  useEffect(() => {
    if (roleData) {
      initialValues.name = roleData.name || "";
      initialValues.roleType = roleData.roleType || "";
    }
    if (roleData) {
      setInitialValues({
        name: roleData.name || "",
        roleType: roleData.roleType || "",
      });
    }
  }, [roleData]);

  return (
    <>
      <Header></Header>
      <div className="flex flex-row bg-whiteSmoke font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1">
          <div className="text-left text-2xl leading-tight p-10">Roles</div>
          <div className="max-w-md mx-auto">
            <div className="text-center text-2xl uppercase mt-20 mb-20">
              Add a new role
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={RoleShema}
              validateOnChange={false}
              onSubmit={formSubmit}
            >
              <Form>
                <div>
                  <label for="name" className="text-gray-400 text-sm">
                    Role Name
                  </label>
                  <Field
                    name="name"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div className="mt-10">
                  <label for="roleType" className="text-gray-400 text-sm">
                    Role type
                  </label>
                  <Field
                    name="roleType"
                    type="text"
                    className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  ></Field>
                  <div className="text-red text-center mb-10">
                    <ErrorMessage name="roleType" />
                  </div>
                </div>
                <div className="flex flex-row">
                  <Link to="/roles">
                    <button
                      type="button"
                      className="bg-transparent uppercase text-blue text-xl border-blue px-16 py-3 rounded border-2 mr-8"
                    >
                      Cancel
                    </button>
                  </Link>

                  <button
                    type="submit"
                    className="bg-blue uppercase text-white text-xl border-blue px-20 py-3 rounded border-2"
                  >
                    Save
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  roleDetails: getRoleDetails(state),
});

const mapDispatchToProps = { createRole, updateRole, fetchRole };
export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
