import React, { useEffect, useMemo, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { connect } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  createGarment,
  fetchGarment,
  updateGarment,
  uploadGarment,
} from "../../actions/garment";
import { garmentDetails, getGarmentDetails } from "../../selectors/garment";
import { listRoles } from "../../actions/role";
import { getRoles } from "../../selectors/role";
import { listBranches } from "../../actions/branch";
import { getBranches } from "../../selectors/branch";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import addIcon from "../../img/add.svg";
import xIcon from "../../img/x.svg";
import {
  generateChildCompanyTreeStructure,
  generateTreeStructure,
  getCompanyBranchTitle,
} from "../../utils/companies";
import keycloak from "../../keycloak";

const GarmentSchema = Yup.object().shape({
  name: Yup.string().required("This field is mandatory"),
  productNumber: Yup.number().required("This field is mandatory"),
  category: Yup.string().required("This field is mandatory"),
  subcategory: Yup.string().required("This field is mandatory"),
});

const startValues = {
  name: "",
  productNumber: 0,
  imageUrl: "X",
  colors: [],
  sizes: ["S", "M"],
  zippers: "true",
  fabric: "",
  category: "",
  subcategory: "",
  status: "draft",
  gender: "m",
  tags: "",
  notes: "",
  availableTo: [],
  active: "true",
  assetPath: "",
};

const sizes = ["XS", "S", "M", "L", "XL"];

const AddGarment = ({
  createGarment,
  updateGarment,
  fetchGarment,
  uploadGarment,
  garmentDetails,
  listBranches,
  branchesList,
}) => {
  const [initialValues, setInitialValues] = useState(startValues);
  const [file, setFile] = useState(null);
  const [selectedColor, setSelectedColor] = useState(0);

  let { id } = useParams();
  const history = useHistory();

  const handleAttachment = async ({ target }) => {
    if (!target.files.length) {
      return false;
    }
    setFile(target.files[0]);
  };

  const addColors = (e) => {
    console.log(e.target.value);
  };

  useEffect(() => {
    listBranches();
    if (id) {
      fetchGarment(id);
    }
  }, []);

  const branchesListData = branchesList.listData;
  const isSuperAdmin = useMemo(
    () =>
      _.intersection(keycloak.tokenParsed.realm_access.roles, ["super_admin"]),
    []
  );

  let filteredCompanies = useMemo(() => {
    if (branchesListData && branchesListData.length) {
      if (isSuperAdmin) {
        return generateTreeStructure(branchesListData, 0);
      } else {
        return generateChildCompanyTreeStructure(
          branchesListData,
          keycloak.idTokenParsed.group_membership[0],
          true
        );
      }
    }
    return [];
  }, [branchesListData, isSuperAdmin]);

  const disregardedAvailableTo = useMemo(() => {
    return _.difference(
      initialValues.availableTo,
      filteredCompanies.map((x) => x.id)
    );
  }, [initialValues, filteredCompanies]);

  const {
    garmentData,
    isFetching: garmentDataIsFetching,
    isFetched: garmentDataIsFetched,
  } = garmentDetails;

  const formSubmit = async (values) => {
    try {
      let garment = null;
      if (values.active === "true") {
        values.active = true;
      } else {
        values.active = false;
      }

      if (values.zippers === "true") {
        values.zippers = true;
      } else {
        values.zippers = false;
      }
      values.availableTo = disregardedAvailableTo.concat(values.availableTo);
      if (id) {
        garment = await updateGarment(id, values);
      } else {
        garment = await createGarment(values);
      }
      if (file) {
        await uploadGarment(garment.id, file);
      }
      history.push("/garments");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id && garmentData) {
      setInitialValues({
        ...startValues,
        name: garmentData.name || "",
        productNumber: parseInt(garmentData.productNumber) || 0,
        gender: garmentData.gender || "",
        category: garmentData.category || "",
        status: garmentData.status || "",
        fabric: garmentData.fabric || "",
        subcategory: garmentData.subcategory || "",
        tags: garmentData.tags || "",
        colors: garmentData.colors || "",
        sizes: garmentData.sizes || "",
        notes: garmentData.notes || "",
        availableTo: garmentData.availableTo || [],
        zippers: garmentData.zippers ? "true" : "false",
        active: garmentData.active ? "true" : "false",
        assetPath: garmentData.assetPath || "",
        imageUrl: garmentData.imageUrl || "",
      });
    }
  }, [id, garmentData]);

  return (
    <>
      <Header></Header>
      <div className="flex flex-row bg-whiteSmoke font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1 mb-20">
          <div className="text-left text-2xl leading-tight p-8">Garments</div>
          <div className="max-w-md mx-auto">
            <div className="text-center text-2xl leading-tight uppercase mt-10 mb-10">
              {id ? "Edit" : "Add a new"} garment
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={GarmentSchema}
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={formSubmit}
            >
              {(props) => {
                const colors = props.values.colors;
                return (
                  <Form>
                    <div className="relative">
                      <label for="name" className="text-gray-400 text-sm">
                        Garment name
                      </label>
                      <Field
                        name="name"
                        type="text"
                        className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      ></Field>
                      <div className="text-red text-center mb-5">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                    <div className="mt-5">
                      <label
                        for="productNumber"
                        className="text-gray-400 text-sm"
                      >
                        Product number
                      </label>
                      <Field
                        name="productNumber"
                        type="number"
                        className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      ></Field>
                      <div className="text-red text-center mb-5">
                        <ErrorMessage name="productNumber" />
                      </div>
                    </div>
                    <div className="mt-5">
                      <label for="imageUrl" className="text-gray-400 text-sm">
                        Upload image
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleAttachment}
                        className="rounded flex-1 block w-52 py-2 px-3 border border-white text-gray-700 bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        className="text-gray-400 text-sm"
                        for="availableTo"
                      >
                        Available To
                      </label>
                      <Field
                        component="select"
                        id="availableTo"
                        name="availableTo"
                        multiple={true}
                        className="rounded flex-1 block w-52 py-2 px-3 border border-white text-gray-700 bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      >
                        <option></option>
                        {filteredCompanies
                          .filter((c) => c.name !== "branches")
                          .map((branch, idx) => {
                            return (
                              <option key={branch.id} value={branch.id}>
                                {getCompanyBranchTitle(branch)}
                              </option>
                            );
                          })}
                        {/* {filteredCompanies.map((branch, idx) => {
                          return (
                            <option key={branch.id} value={branch.id}>
                              {branch.name}
                            </option>
                          );
                        })} */}
                      </Field>
                      <div className="text-red text-center mb-5">
                        <ErrorMessage name="availableTo" />
                      </div>
                    </div>
                    <div className="mt-5">
                      <label for="colors" className="text-gray-400 text-sm">
                        Colors
                      </label>
                      <br />
                      <div className="flex flex-row flex-wrap">
                        {props.values.colors.map((x, index) => (
                          <div
                            className={`rounded-full mr-2 w-12 h-12 border-2 ${
                              index === selectedColor
                                ? "border-lightBlue border-3"
                                : ""
                            }`}
                            style={{ backgroundColor: x }}
                            onClick={() => {
                              setSelectedColor(index);
                            }}
                          >
                            <div
                              className="w-4 h-4 rounded-full bg-white"
                              onClick={() => {
                                const newColors = [...colors];
                                newColors.splice(index, 1);
                                props.setFieldValue("colors", newColors);
                              }}
                            >
                              <img className="p-1" src={xIcon} alt="remove" />
                            </div>
                          </div>
                        ))}
                        <div
                          className="bg-blue rounded-full w-12 h-12 ml-5"
                          onClick={() => {
                            props.setFieldValue("colors", [
                              ...colors,
                              "#FFFFFF",
                            ]);
                          }}
                        >
                          <img className="p-3" src={addIcon} alt="Add" />
                        </div>
                      </div>
                      {selectedColor < colors.length && (
                        <HexColorPicker
                          className="my-5"
                          onChange={(color) => {
                            const newColors = colors;
                            newColors[selectedColor] = color;
                            props.setFieldValue("colors", [...newColors]);
                          }}
                        />
                      )}
                      {/* <ColorList
                    colors={colors}
                    colorFormat="rgb"
                  /> */}
                      {/* <input
                    name="colors"
                    type="color"
                    onSelect={addColors}
                  /> */}
                    </div>
                    <div className="mt-5">
                      <label for="zippers" className="text-gray-400 text-sm">
                        Zippers
                      </label>
                      <div className="flex items-center gap-8">
                        <div role="group" aria-labelledby="gender">
                          <label className="inline-flex items-center">
                            <Field
                              type="radio"
                              name="zippers"
                              className="h-5 w-5 text-red-600"
                              value="true"
                            />
                            <span className="ml-2 text-gray-400 text-sm">
                              True
                            </span>
                          </label>
                          <br />
                          <label className="inline-flex items-center">
                            <Field
                              type="radio"
                              name="zippers"
                              className="h-5 w-5 text-red-600"
                              value="false"
                            />
                            <span className="ml-2 text-gray-400 text-sm">
                              False
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <label for="sizes" className="text-gray-400 text-sm">
                        Sizes
                      </label>
                      <Field
                        component="select"
                        id="sizes"
                        name="sizes"
                        multiple={true}
                        className="rounded block w-52 border-white flex-1 appearance-none border border-white py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      >
                        <option></option>
                        {sizes.map((size, idx) => {
                          return (
                            <option key={size.id} value={size}>
                              {size}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <div className="mt-5">
                      <label for="fabric" className="text-gray-400 text-sm">
                        Fabric
                      </label>
                      <Field
                        name="fabric"
                        type="text"
                        className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      ></Field>
                    </div>
                    <div className="mt-5">
                      <label for="category" className="text-gray-400 text-sm">
                        Category
                      </label>
                      <Field
                        name="category"
                        type="text"
                        className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      ></Field>
                    </div>
                    <div className="mt-5">
                      <label
                        for="subcategory"
                        className="text-gray-400 text-sm"
                      >
                        Subcategory
                      </label>
                      <Field
                        name="subcategory"
                        type="text"
                        className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      ></Field>
                    </div>
                    <div className="mt-5">
                      <label for="status" className="text-gray-400 text-sm">
                        Status
                      </label>
                      <br />
                      <Field
                        component="select"
                        id="status"
                        name="status"
                        multiple={false}
                        // onChange={handleChangeStatus}
                        className="rounded flex-1 block w-52 py-2 px-3 border border-white bg-white focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      >
                        <option></option>
                        <option
                          value={"draft"}
                          className="ml-2 text-gray-400 text-sm"
                        >
                          Draft
                        </option>
                        <option
                          value={"in_discussion"}
                          className="ml-2 text-gray-400 text-sm"
                        >
                          In discussion
                        </option>
                        <option
                          value={"approved"}
                          className="ml-2 text-gray-400 text-sm"
                        >
                          Approved
                        </option>
                      </Field>
                    </div>
                    <div className="mt-5">
                      <label for="tags" className="text-gray-400 text-sm">
                        Tags
                      </label>
                      <Field
                        name="tags"
                        type="text"
                        className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      ></Field>
                    </div>
                    <div className="mt-5">
                      <label for="notes" className="text-gray-400 text-sm">
                        Notes
                      </label>
                      <Field
                        name="notes"
                        type="text"
                        className="rounded border-white flex-1 appearance-none border border-white w-full py-4 px-4 bg-white text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      ></Field>
                    </div>
                    <div className="mt-5">
                      <label for="name" className="text-gray-400 text-sm">
                        Gender
                      </label>
                      <div className="flex items-center gap-8">
                        <div role="group" aria-labelledby="gender">
                          <label className="inline-flex items-center">
                            <Field
                              type="radio"
                              name="gender"
                              className="h-5 w-5 text-red-600"
                              value="m"
                            />
                            <span className="ml-2 text-gray-400 text-sm">
                              Male
                            </span>
                          </label>
                          <br />
                          <label className="inline-flex items-center">
                            <Field
                              type="radio"
                              name="gender"
                              className="h-5 w-5 text-red-600"
                              value="f"
                            />
                            <span className="ml-2 text-gray-400 text-sm">
                              Female
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <label for="name" className="text-gray-400 text-sm">
                        Active
                      </label>
                      <div className="flex items-center gap-8">
                        <div role="group" aria-labelledby="active">
                          <label className="inline-flex items-center">
                            <Field
                              type="radio"
                              name="active"
                              className="h-5 w-5 text-red-600"
                              value="true"
                            />
                            <span className="ml-2 text-gray-400 text-sm">
                              Active
                            </span>
                          </label>
                          <br />
                          <label className="inline-flex items-center">
                            <Field
                              type="radio"
                              name="active"
                              className="h-5 w-5 text-red-600"
                              value="false"
                            />
                            <span className="ml-2 text-gray-400 text-sm">
                              Inactive
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row mt-10">
                      <Link to="/garments">
                        <button
                          type="button"
                          className="bg-transparent uppercase text-blue text-xl border-blue px-16 py-3 rounded border-2 mr-8"
                        >
                          Cancel
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="bg-blue uppercase text-white text-xl border-blue px-20 py-3 rounded border-2"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  garmentDetails: getGarmentDetails(state),
  branchesList: getBranches(state),
});

const mapDispatchToProps = {
  createGarment,
  updateGarment,
  fetchGarment,
  listBranches,
  uploadGarment,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGarment);
