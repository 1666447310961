import getAxiosInstance from '../config/http';

export const CREATE_AVATAR_REQUEST = 'AVATAR@CREATE_AVATAR_REQUEST';
export const CREATE_AVATAR_SUCCESS = 'AVATAR@CREATE_AVATAR_SUCCESS';
export const CREATE_AVATAR_FAILURE = 'AVATAR@CREATE_AVATAR_FAILURE';

export const createAvatar = avatarData => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_AVATAR_REQUEST,
  });
  try{
    const { data } = await http.post('/productmng/avatar', avatarData);
    dispatch({
      type: CREATE_AVATAR_SUCCESS,
      payload: {
        avatarData: data,
      },
    });
    return data;
  }
  catch(e){
    dispatch({
      type: CREATE_AVATAR_FAILURE,
    });
    throw e;
  }
};

export const LIST_AVATAR_REQUEST = 'AVATAR@LIST_AVATAR_REQUEST';
export const LIST_AVATAR_SUCCESS = 'AVATAR@LIST_AVATAR_SUCCESS';
export const LIST_AVATAR_FAILURE = 'AVATAR@LIST_AVATAR_FAILURE';

export const listAvatars = params => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_AVATAR_REQUEST,
  });
  try{
    const avatarData = await http.get('/productmng/avatar/list/all', {params});
    dispatch({
      type: LIST_AVATAR_SUCCESS,
      payload: {
        listData: avatarData.data.results,
        total: avatarData.data.total,
      }
    });
  }catch(e){
    dispatch({
      type: LIST_AVATAR_FAILURE,
    });
  }
};

export const FETCH_AVATAR_REQUEST = 'AVATAR@FETCH_AVATAR_REQUEST';
export const FETCH_AVATAR_SUCCESS = 'AVATAR@FETCH_AVATAR_SUCCESS';
export const FETCH_AVATAR_FAILURE = 'AVATAR@FETCH_AVATAR_FAILURE';

export const fetchAvatar = avatarId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_AVATAR_REQUEST,
  });
  try{
    const avatarData = await http.get(`/productmng/avatar/${avatarId}`);
    dispatch({
      type: FETCH_AVATAR_SUCCESS,
      payload: {
        avatarData: avatarData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_AVATAR_FAILURE,
    });
  };
};

export const UPDATE_AVATAR_REQUEST = 'AVATAR@UPDATE_AVATAR_REQUEST';
export const UPDATE_AVATAR_SUCCESS = 'AVATAR@UPDATE_AVATAR_SUCCESS';
export const UPDATE_AVATAR_FAILURE = 'AVATAR@UPDATE_AVATAR_FAILURE';

export const updateAvatar = (id, avatarData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_AVATAR_REQUEST,
  });
  try{
    const data = await http.put(`/productmng/avatar/${id}`, avatarData);
    dispatch({
      type: UPDATE_AVATAR_SUCCESS,
      payload: {
        avatarData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_AVATAR_FAILURE,
    });
  };
};

export const DELETE_AVATAR_REQUEST = 'AVATAR@DELETE_AVATAR_REQUEST';
export const DELETE_AVATAR_SUCCESS = 'AVATAR@DELETE_AVATAR_SUCCESS';
export const DELETE_AVATAR_FAILURE = 'AVATAR@DELETE_AVATAR_FAILURE';

export const deleteAvatar = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_AVATAR_REQUEST,
  });
  try{
    const data = await http.delete(`/productmng/avatar/${id}`);
    dispatch({
      type: DELETE_AVATAR_SUCCESS,
      payload: {
        avatarId: id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_AVATAR_FAILURE,
    });
  }
};
