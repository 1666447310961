import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { listGarments, deleteGarment } from "../../actions/garment";
import { connect } from "react-redux";
import GarmentList from "../../components/Garments/GarmentList";
import Header from "../../components/Header";
import Paging from "../../components/Paging";
import Sidebar from "../../components/Sidebar";
import BgImage from "../../components/BgImage";
import { getGarments } from "../../selectors/garment";
import { Link } from "react-router-dom";
import add from "../../img/add.svg";
import filter from "../../img/blue-icons/search.svg";
import filterIcon from "../../img/blue-icons/filter.svg";
import { getBranches } from "../../selectors/branch";
import { listBranches } from "../../actions/branch";

const initialValues = {
  name: "",
};

const GarmentListPage = ({ listGarments, deleteGarment, garmentsList, branchesList, listBranches }) => {
  const { isFetching, isFetched, listData, total } = garmentsList;
  const {
    listData: garmentsListData,
    isFetching: garmentsListFetching,
    isFetched: garmentsListFetched,
  } = garmentsList;

  const [sortParams, setSortParams] = useState({
    sortParam: "name",
    sortOrder: "ASC",
  });

  const [filterParams, setFilterParams] = useState({
    name: "",
  });

  const [filtersOpen, setFiltersOpen] = useState(false);
  const branchesListData = branchesList.listData;

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
  };

  const [pageParams, setPageParams] = useState({ limit: 10, offset: 0 });

  const formSubmit = async (values) => {
    setFilterParams(values);
  };

  useEffect(() => {
    listBranches();
    listGarments({ ...sortParams, ...filterParams, ...pageParams });
  }, [sortParams, filterParams, pageParams]);

  const toggleSort = (sortParam) => {
    // ASC means true, desc means false
    const oldSortOrder = sortParams.sortOrder === "ASC" ? true : false;
    let newSortOrder =
      sortParams.sortParam === sortParam ? !oldSortOrder : true;
    setSortParams({
      sortParam,
      sortOrder: newSortOrder ? "ASC" : "DESC",
    });
  };

  return (
    <div>
      <Header></Header>
      <div className="flex flex-row font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1">
          <BgImage></BgImage>
          <div className="text-left text-2xl leading-tight p-10">Garments</div>
          <div className="mx-auto m-0 p-10 relative">
            <div className="flex flex-row justify-between content-center justify-center place-content-center place-items-center self-center	justify-items-center	justify-self-center">
              <div>
                <div className="flex flex-row bg-white rounded-full py-2 px-6" onClick={toggleFiltersOpen}>
                  <div className="mr-4 text-blue text-lg">
                    <a href="#">Filters</a>
                  </div>
                  <img src={filterIcon} alt="filter" />
                </div>
                <div
                  className={`absolute t-0 l-0 w-96 p-5 min-h-64 bg-white rounded-md shadow ${
                    filtersOpen ? "block" : "hidden"
                  }`}
                >
                  <div className="flex flex-col">
                    <Formik
                      validateOnChange={false}
                      enableReinitialize={true}
                      initialValues={initialValues}
                      onSubmit={formSubmit}
                    >
                      <Form className="flex flex-col">
                        <div className="">
                          <Field
                            name="name"
                            type="text"
                            placeholder="Name"
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            name="productNumber"
                            type="text"
                            placeholder="Product number"
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            component="select"
                            id="status"
                            name="status"
                            multiple={false}
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke text-gray-700 w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          >
                            <option>Status</option>
                            <option value={"draft"}>Draft</option>
                            <option value={"in_discussion"}>
                              In discussion
                            </option>
                            <option value={"approved"}>Approved</option>
                          </Field>
                        </div>
                        {/* <div className="mt-5">
                          <Field
                            component="select"
                            id="branchId"
                            name="branchId"
                            multiple={false}
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke text-gray-700 w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          >
                            <option>Branch</option>
                            {branchesListData.map((branch, idx) => {
                              return (
                                <option
                                  className="rounded text-gray"
                                  key={branch.id}
                                  value={branch.id}
                                >
                                  {branch.name}
                                </option>
                              );
                            })}
                          </Field>
                        </div> */}
                        <div className="mt-5">
                          <Field
                            component="select"
                            id="active"
                            name="active"
                            multiple={false}
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke text-gray-700 w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          >
                            <option>Active</option>
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            component="select"
                            id="zippers"
                            name="zippers"
                            multiple={false}
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke text-gray-700 w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          >
                            <option>Zippers</option>
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            component="select"
                            id="gender"
                            name="gender"
                            placeholder="Filter by gender"
                            multiple={false}
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke text-gray-700 w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          >
                            <option>Gender</option>
                            <option value="f">Female</option>
                            <option value="m">Male</option>u
                          </Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            name="category"
                            type="text"
                            placeholder="Category"
                            className="rounded-full py-2 px-4 appearance-none border border-whiteSmoke bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            name="subcategory"
                            type="text"
                            placeholder="Subcategory"
                            className="rounded-full py-2 px-4 appearance-none border border-whiteSmoke bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            name="fabric"
                            type="text"
                            placeholder="Fabric"
                            className="rounded-full py-2 px-4 appearance-none border border-whiteSmoke bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            name="tags"
                            type="text"
                            placeholder="Tags"
                            className="rounded-full py-2 px-4 appearance-none border border-whiteSmoke bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            name="notes"
                            type="text"
                            placeholder="Notes"
                            className="rounded-full py-2 px-4 appearance-none border border-whiteSmoke bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <button
                          type="submit"
                          className="mt-5 w-full bg-blue rounded-sm text-white text-lg p-2 pl-4 text-center uppercase"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
              <Link to="/addGarment">
                <div className="flex flex-row flex-1 align-items align-middle items-center justify-center">
                  <div className="mr-4 text-taupeGray text-lg">Add New Garment</div>
                  <img
                    src={add}
                    className="rounded-full bg-blue w-10 h-10 p-3"
                    alt="Add button"
                  />
                </div>
              </Link>
            </div>
            <GarmentList
              results={garmentsListData}
              deleteGarment={deleteGarment}
              toggleSort={toggleSort}
              branchesList={branchesListData}
            />
            <div className="flex flex-row items-end justify-end">
              <Paging total={total} setPageParams={setPageParams}></Paging>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  garmentsList: getGarments(state),
  branchesList: getBranches(state),
});

const mapDispatchToProps = {
  listGarments,
  deleteGarment,
  listBranches,
};

export default connect(mapStateToProps, mapDispatchToProps)(GarmentListPage);
