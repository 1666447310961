import React from "react";
import _ from "lodash";

import { Link, useLocation } from "react-router-dom";
import DashboardBg from "../components/DashboardBg";

//Assets
import logo from "../img/dashboard-logo.svg";
import role from "../img/blue-icons/role.svg";
import company from "../img/blue-icons/company.svg";
import branch from "../img/blue-icons/branch.svg";
import user from "../img/blue-icons/user.svg";
import avatar from "../img/blue-icons/avatar.svg";
import garment from "../img/blue-icons/garment.svg";

import activeRoles from "../img/white-icons/role.svg";
import activeCompanies from "../img/white-icons/company.svg";
import activeBranches from "../img/white-icons/branch.svg";
import activeUsers from "../img/white-icons/user.svg";
import activeAvatars from "../img/white-icons/avatar.svg";
import activeGarments from "../img/white-icons/garment.svg";
import keycloak from "../keycloak";

const items = [
  // {
  //   title: "Roles",
  //   path: "/roles",
  //   icon: role,
  //   activeIcon: activeRoles,
  // },
  {
    title: "Companies",
    path: "/companies",
    icon: company,
    activeIcon: activeCompanies,
    allowedRoles: ["super_admin", "dealer_admin", "seller_admin"],
  },
  // {
  //   title: "Branches",
  //   path: "/branches",
  //   icon: branch,
  //   activeIcon: activeBranches,
  // },
  {
    title: "Users",
    path: "/users",
    icon: user,
    activeIcon: activeUsers,
    allowedRoles: ["super_admin", "dealer_admin", "seller_admin"],
  },
  {
    title: "Avatars",
    path: "/avatars",
    icon: avatar,
    activeIcon: activeAvatars,
  },
  {
    title: "Garments",
    path: "/garments",
    icon: garment,
    activeIcon: activeGarments,
  },
  {
    title: "Scenes",
    path: "/scenes",
    icon: role,
    activeIcon: activeRoles,
  },
];

const Dashboard = () => {
  const location = useLocation();
  return (
    <>
      <DashboardBg></DashboardBg>
      <div className="p-10">
        <img src={logo} alt="Texet Logo" />
      </div>
      <div className="container mx-auto font-poppinsRegular">
        <div className="grid lg:grid-cols-3 gap-16 grid-cols-1">
          {items.map((item, idx) => {
            const isAllowed =
              !item.allowedRoles ||
              _.intersection(
                item.allowedRoles,
                keycloak.tokenParsed.realm_access.roles
              ).length > 0;
            if (!isAllowed) {
              return <></>;
            }
            const active = location.pathname === item.path;
            return (
              <Link to={item.path}>
                <div className="shadow-2xl rounded-2xl p-6 bg-gray-300 opacity-80 h-72 duration-300 ease-in-out transition duration-300 ease-in-out hover:opacity-100">
                  <div className="flex flex-col justify-center h-full">
                    <img
                      src={item.icon}
                      className={`w-16 h-16 mb-5 mt-16 hover:$ac`}
                      alt="menu icon"
                    />
                    <p className="text-3xl text-left uppercase">{item.title}</p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
