import React, { useState } from "react";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

//Assets
import logo from "../img/VR_logo.svg";
import profile from "../img/white-icons/profile.svg";

const Header = () => {
  const { keycloak } = useKeycloak();
  const logout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  const [filtersOpen, setFiltersOpen] = useState(false);

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
  };

  return (
    <div>
      <nav className="bg-blue">
        <div className="flex flex-row items-center justify-between h-16">
          <Link to="/dashboard">
            <div className="ml-14 flex flex-row justify-between content-center justify-center place-content-center place-items-center self-center	justify-items-center	justify-self-center">
              <img src={logo} />
            </div>
          </Link>
          <div>
            <div className="flex flex-row mr-12" onClick={toggleFiltersOpen}>
              <a href="#" className="text-white text-base font-poppinsRegular mr-3">
                My Account
              </a>
              <div>
                <img src={profile} alt="Profile" className="w-5" />
              </div>
            </div>
            <div
              className={`absolute t-0 l-0 w-36 p-3 min-h-32 bg-whiteSmoke rounded-md shadow ${
                filtersOpen ? "block" : "hidden"
              }`}
            >
              <div className="flex flex-col font-poppinsRegular">
                <Link to={`/editUser/${keycloak.subject}`}>Edit profile</Link>
                <Link onClick={() => logout()} className="mt-5">Log out</Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default Header;
