import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { listUsers, deleteUser } from "../../actions/user";
import { listBranches } from "../../actions/branch";
import { fetchCompany, listCompanies } from "../../actions/company";
import { getBranches } from "../../selectors/branch";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import UserList from "../../components/Users/UserList";
import { getUsers } from "../../selectors/user";
import { getCompanies } from "../../selectors/company";
import { listRoles } from "../../actions/role";
import Header from "../../components/Header";
import Paging from "../../components/Paging";
import Sidebar from "../../components/Sidebar";
import BgImage from "../../components/BgImage";
import add from "../../img/add.svg";
import filterIcon from "../../img/blue-icons/filter.svg";

const initialValues = {
  // firstName: "",
  // username: "",
};

const UserListPage = ({
  listUsers,
  deleteUser,
  usersList,
  companiesList,
  listCompanies,
  fetchCompany,
}) => {
  const { isFetching, isFetched, listData, total } = usersList;
  const {
    listData: usersListData,
    isFetching: usersListFetching,
    isFetched: usersListFetched,
  } = usersList;

  let { id } = useParams();

  const [filterParams, setFilterParams] = useState({});

  const [pageParams, setPageParams] = useState({ limit: 10, offset: 0 });

  const [filtersOpen, setFiltersOpen] = useState(false);

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
  };

  useEffect(() => {
    listCompanies({ max: 10000, offset: 0 });
    listUsers({ ...filterParams, ...pageParams });
    fetchCompany(id);
  }, [filterParams, pageParams]);

  const companiesListData = companiesList.listData;

  const formSubmit = async (values) => {
    setFilterParams(values);
  };

  return (
    <>
      <Header></Header>
      <div className="flex flex-row font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1">
          <BgImage></BgImage>
          <div className="text-left text-2xl leading-tight p-10">Users</div>
          <div className="mx-auto m-0 p-10 relative">
            <div className="flex flex-row justify-between content-center justify-center place-content-center place-items-center self-center	justify-items-center	justify-self-center">
              <div>
                <div
                  className="flex flex-row bg-white rounded-full py-2 px-6"
                  onClick={toggleFiltersOpen}
                >
                  <div className="mr-4 text-blue text-lg">
                    <a href="#">Filters</a>
                  </div>
                  <img src={filterIcon} alt="filter" />
                </div>
                <div
                  className={`absolute t-0 l-0 w-96 p-5 min-h-32 bg-white rounded-md shadow ${
                    filtersOpen ? "block" : "hidden"
                  }`}
                >
                  <div className="flex flex-col">
                    <Formik
                      validateOnChange={false}
                      enableReinitialize={true}
                      initialValues={initialValues}
                      onSubmit={formSubmit}
                    >
                      <Form className="flex flex-col">
                        {/* <div>
                          <Field
                            component="select"
                            id="company.id"
                            name="company.id"
                            multiple={false}
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke w-full bg-whiteSmoke text-gray-700 placeholder-white text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          >
                            <option>Company</option>
                            {companiesListData.map((company, idx) => {
                              return (
                                <option
                                  className="rounded text-gray"
                                  key={company.id}
                                  value={company.id}
                                >
                                  {company.name}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            component="select"
                            id="status"
                            name="status"
                            placeholder="Active"
                            multiple={false}
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke text-gray-700 w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          >
                            <option>Active</option>
                            <option value={true}>Enabled</option>
                            <option value={false}>Disabled</option>u
                          </Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            name="firstName"
                            type="text"
                            placeholder="Name"
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div> */}
                        <div className="mt-5">
                          <Field
                            name="search"
                            type="text"
                            placeholder="Search"
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <button
                          type="submit"
                          className="mt-5 w-full bg-blue rounded-sm text-white text-lg p-2 pl-4 text-center uppercase"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
              <Link to="/addUser">
                <div className="flex flex-row flex-1 align-items align-middle items-center justify-center">
                  <div className="mr-4 text-taupeGray text-lg">
                    Add New User
                  </div>
                  <img
                    src={add}
                    className="rounded-full bg-blue w-10 h-10 p-3"
                    alt="Add button"
                  />
                </div>
              </Link>
            </div>
            <UserList results={usersListData} deleteUser={deleteUser} />
            <div className="flex flex-row items-end justify-end">
              <Paging total={total} setPageParams={setPageParams}></Paging>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  usersList: getUsers(state),
  companiesList: getCompanies(state),
});

const mapDispatchToProps = {
  listUsers,
  deleteUser,
  listCompanies,
  fetchCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListPage);
