import getAxiosInstance from "../config/http";

export const CREATE_COMPANY_REQUEST = "COMPANY@CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "COMPANY@CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE = "COMPANY@CREATE_COMPANY_FAILURE";

export const createCompany = (companyData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_COMPANY_REQUEST,
  });
  try {
    const { data } = await http.post("/usermng/company", companyData);
    dispatch({
      type: CREATE_COMPANY_SUCCESS,
      payload: {
        companyData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_COMPANY_FAILURE,
    });
    throw e;
  }
};

export const LIST_COMPANY_REQUEST = "COMPANY@LIST_COMPANY_REQUEST";
export const LIST_COMPANY_SUCCESS = "COMPANY@LIST_COMPANY_SUCCESS";
export const LIST_COMPANY_FAILURE = "COMPANY@LIST_COMPANY_FAILURE";

export const listCompanies = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_COMPANY_REQUEST,
  });
  try {
    const companyData = await http.get("/usermng/company", { params });
    // console.log(companyData);
    dispatch({
      type: LIST_COMPANY_SUCCESS,
      payload: {
        listData: companyData.data,
        // total: companyData.data.total,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_COMPANY_FAILURE,
    });
    throw(e);
  }
};

export const FETCH_COMPANY_REQUEST = "COMPANY@FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "COMPANY@FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAILURE = "COMPANY@FETCH_COMPANY_FAILURE";

export const fetchCompany = (companyId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_COMPANY_REQUEST,
  });
  try {
    const companyData = await http.get(`/usermng/company/${companyId}`);
    dispatch({
      type: FETCH_COMPANY_SUCCESS,
      payload: {
        companyData: companyData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_COMPANY_FAILURE,
    });
    throw(e);
  }
};

export const UPDATE_COMPANY_REQUEST = "COMPANY@UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "COMPANY@UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "COMPANY@UPDATE_COMPANY_FAILURE";

export const updateCompany = (id, companyData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_COMPANY_REQUEST,
  });
  try {
    const data = await http.put(`/usermng/company/${id}`, companyData);
    dispatch({
      type: UPDATE_COMPANY_SUCCESS,
      payload: {
        companyData: data,
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_COMPANY_FAILURE,
    });
    throw(e);
  }
};

export const DELETE_COMPANY_REQUEST = "COMPANY@DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "COMPANY@DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "COMPANY@DELETE_COMPANY_FAILURE";

export const deleteCompany = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_COMPANY_REQUEST,
  });
  try {
    const data = await http.delete(`/usermng/company/${id}`);
    dispatch({
      type: DELETE_COMPANY_SUCCESS,
      payload: {
        companyId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_COMPANY_FAILURE,
    });
  }
};
