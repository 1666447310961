import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { listRoles, deleteRole } from "../../actions/role";
import Header from "../../components/Header";
import Paging from "../../components/Paging";
import RoleList from "../../components/Roles/RoleList";
import { getRoles } from "../../selectors/role";
import Sidebar from "../../components/Sidebar";
import BgImage from "../../components/BgImage";
import add from "../../img/add.svg";
import filterIcon from "../../img/blue-icons/filter.svg";

const initialValues = {
  name: "",
};

const RolesListPage = ({ listRoles, deleteRole, rolesList }) => {
  const { isFetching, isFetched, listData, total } = rolesList;
  const {
    listData: rolesListData,
    isFetching: rolesListFetching,
    isFetched: rolesListFetched,
  } = rolesList;

  const [sortParams, setSortParams] = useState({
    sortParam: "name",
    sortOrder: "ASC",
  });

  const [filterParams, setFilterParams] = useState({
    name: "",
  });

  const [filtersOpen, setFiltersOpen] = useState(false);

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
  };

  const [pageParams, setPageParams] = useState({ limit: 10, offset: 0 });

  useEffect(() => {
    listRoles({ ...sortParams, ...filterParams, ...pageParams });
  }, [sortParams, filterParams, pageParams]);

  const formSubmit = async (values) => {
    setFilterParams(values);
  };

  const toggleSort = (sortParam) => {
    // ASC means true, desc means false
    const oldSortOrder = sortParams.sortOrder === "ASC" ? true : false;
    let newSortOrder =
      sortParams.sortParam === sortParam ? !oldSortOrder : true;
    setSortParams({
      sortParam,
      sortOrder: newSortOrder ? "ASC" : "DESC",
    });
  };

  return (
    <>
      <Header></Header>
      <div className="flex flex-row font-poppinsRegular">
        <Sidebar></Sidebar>
        <div className="flex-1">
          <BgImage></BgImage>
          <div className="text-left text-2xl leading-tight p-10">Roles</div>
          <div className="mx-auto m-0 p-10 relative">
            <div className="flex flex-row justify-between content-center justify-center place-content-center place-items-center self-center	justify-items-center	justify-self-center">
              <div>
                <div className="flex flex-row bg-white rounded-full py-2 px-6" onClick={toggleFiltersOpen}>
                  <div className="mr-4 text-blue text-lg">
                    <a href="#">Filters</a>
                  </div>
                  <img src={filterIcon} alt="filter" />
                </div>
                <div
                  className={`absolute t-0 l-0 w-96 p-5 min-h-64 bg-white rounded-md shadow ${
                    filtersOpen ? "block" : "hidden"
                  }`}
                >
                  <div className="flex flex-col">
                    <Formik
                      validateOnChange={false}
                      enableReinitialize={true}
                      initialValues={initialValues}
                      onSubmit={formSubmit}
                    >
                      <Form className="flex flex-col">
                        <div className="mt-5">
                          <Field
                            name="name"
                            type="text"
                            placeholder="Name"
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <div className="mt-5">
                          <Field
                            id="roleType"
                            name="roleType"
                            type="text"
                            placeholder="Type"
                            className="rounded-full py-2 px-4 flex-1 block w-52 py-2 px-3 text-gray-700 border border-whiteSmoke bg-whiteSmoke focus:outline-none focus:ring-primary-500 focus:border-primary-500 mr-10"
                          ></Field>
                        </div>
                        <button
                          type="submit"
                          className="mt-5 w-full bg-blue rounded-sm text-white text-lg p-2 pl-4 text-center uppercase"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
              <Link to="/addRole">
                <div className="flex flex-row flex-1 align-items align-middle items-center justify-center">
                  <div className="mr-4 text-taupeGray text-lg">
                    Add New Role
                  </div>
                  <img
                    src={add}
                    className="rounded-full bg-blue w-10 h-10 p-3"
                    alt="Add button"
                  />
                </div>
              </Link>
            </div>
            <RoleList
              results={rolesListData}
              deleteRole={deleteRole}
              toggleSort={toggleSort}
            />
            <div className="flex flex-row items-end justify-end">
              <Paging total={total} setPageParams={setPageParams}></Paging>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  rolesList: getRoles(state),
});

const mapDispatchToProps = {
  listRoles,
  deleteRole,
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesListPage);
