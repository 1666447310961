import React from "react";

const BgImage = () => {
  return (
    // <div className="bg-content h-screen fixed top-0 bottom-0 left-0 right-0 opacity-20 -z-10">
    // </div>
    <div className="bg-content fixed top-0 left-0 bottom-0 right-0 bg-center bg-no-repeat bg-cover opacity-20 -z-10 overflow-y-hidden"></div>
  );
};

export default BgImage;
