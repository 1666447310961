import getAxiosInstance from '../config/http';

export const CREATE_BRANCH_REQUEST = 'BRANCH@CREATE_BRANCH_REQUEST';
export const CREATE_BRANCH_SUCCESS = 'BRANCH@CREATE_BRANCH_SUCCESS';
export const CREATE_BRANCH_FAILURE = 'BRANCH@CREATE_BRANCH_FAILURE';

export const createBranch = branchData => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_BRANCH_REQUEST,
  });
  try{
    const { data } = await http.post('/usermng/branch', branchData);
    dispatch({
      type: CREATE_BRANCH_SUCCESS,
      payload: {
        branchData: data,
      },
    });
    return data;
  }
  catch(e){
    dispatch({
      type: CREATE_BRANCH_FAILURE,
    });
    throw e;
  }
};

export const LIST_BRANCH_REQUEST = 'BRANCH@LIST_BRANCH_REQUEST';
export const LIST_BRANCH_SUCCESS = 'BRANCH@LIST_BRANCH_SUCCESS';
export const LIST_BRANCH_FAILURE = 'BRANCH@LIST_BRANCH_FAILURE';

export const listBranches = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_BRANCH_REQUEST,
  });
  try{
    const branchData = await http.get('/usermng/branch', {params});
    console.log(branchData);
    dispatch({
      type: LIST_BRANCH_SUCCESS,
      payload: {
        listData: branchData.data,
        // total: branchData.data.total,
      }
    });
  }catch(e){
    dispatch({
      type: LIST_BRANCH_FAILURE,
    });
  }
};

export const FETCH_BRANCH_REQUEST = 'BRANCH@FETCH_BRANCH_REQUEST';
export const FETCH_BRANCH_SUCCESS = 'BRANCH@FETCH_BRANCH_SUCCESS';
export const FETCH_BRANCH_FAILURE = 'BRANCH@FETCH_BRANCH_FAILURE';

export const fetchBranch = branchId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_BRANCH_FAILURE,
  });
  try{
    const branchData = await http.get(`/usermng/branch/${branchId}`);
    dispatch({
      type: FETCH_BRANCH_SUCCESS,
      payload: {
        branchData: branchData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_BRANCH_FAILURE,
    });
  };
};

export const UPDATE_BRANCH_REQUEST = 'BRANCH@UPDATE_BRANCH_REQUEST';
export const UPDATE_BRANCH_SUCCESS = 'BRANCH@UPDATE_BRANCH_SUCCESS';
export const UPDATE_BRANCH_FAILURE = 'BRANCH@UPDATE_BRANCH_FAILURE';

export const updateBranch = (id, branchData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_BRANCH_REQUEST,
  });
  try{
    const data = await http.put(`/usermng/branch/${id}`, branchData);
    dispatch({
      type: UPDATE_BRANCH_SUCCESS,
      payload: {
        branchData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_BRANCH_FAILURE,
    });
  };
};

export const DELETE_BRANCH_REQUEST = 'BRANCH@DELETE_BRANCH_REQUEST';
export const DELETE_BRANCH_SUCCESS = 'BRANCH@DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILURE = 'BRANCH@DELETE_BRANCH_FAILURE';

export const deleteBranch = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_BRANCH_REQUEST,
  });
  try{
    const data = await http.delete(`/usermng/branch/${id}`);
    dispatch({
      type: DELETE_BRANCH_SUCCESS,
      payload: {
        branchId: id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_BRANCH_FAILURE,
    });
  }
};
