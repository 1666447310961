import _remove from 'lodash/remove';
import * as BranchActions from '../actions/branch';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  branchesList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total:0,
  },
  branchDetails: {
    isFetching: false,
    isFetched: false,
    branchData: null,
  },
};

const setBranchListFetching = fetching => state => ({
  ...state,
  branchesList: {
    ...state.branchesList,
    isFetching: fetching,
  },
});

const setBranchList = (state, { payload: { listData, total } }) => ({
  ...state,
  branchesList: {
    ...state.branchesList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setBranchFetching = fetching => state => ({
  ...state,
  branchDetails: {
    ...state.branchDetails,
    isFetching: fetching,
  },
});

const setBranch = (state, { payload: { branchData } }) => ({
  ...state,
  branchDetails: {
    ...state.branchDetails,
    branchData,
    isFetching: false,
    isFetched: true,
  },
});

const setBranchDeleted = (state, { payload: { branchId } }) => {
  const newListData = state.branchesList.listData;
  _remove(newListData, { id: branchId });

  const newState = {
    ...state,
    branchesList: {
      ...state.branchesList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [BranchActions.LIST_BRANCH_REQUEST]: setBranchListFetching(true),
  [BranchActions.LIST_BRANCH_SUCCESS]: setBranchList,
  [BranchActions.LIST_BRANCH_FAILURE]: setBranchListFetching(false),
  [BranchActions.FETCH_BRANCH_REQUEST]: setBranchFetching(true),
  [BranchActions.FETCH_BRANCH_SUCCESS]: setBranch,
  [BranchActions.FETCH_BRANCH_FAILURE]: setBranchFetching(false),
  [BranchActions.DELETE_BRANCH_SUCCESS]: setBranchDeleted,
};

export default reducerWithActionMap(actionMap, initialState);


