import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { listBranches, updateBranch, deleteBranch } from "../../actions/branch";
import { listCompanies } from "../../actions/company";
import { getBranches } from "../../selectors/branch";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BranchList from "../../components/Branches/BranchList";
import Header from "../../components/Header";
import Paging from "../../components/Paging";
import Sidebar from "../../components/Sidebar";
import BgImage from "../../components/BgImage";
import add from "../../img/add.svg";
import filterIcon from "../../img/blue-icons/filter.svg";

const initialValues = {
  name: "",
};

const BranchListPage = ({
  listBranches,
  updateBranch,
  deleteBranch,
  branchesList,
}) => {
  const { isFetching, isFetched, listData, total } = branchesList;
  const {
    listData: branchesListData,
    isFetching: branchesListFetching,
    isFetched: branchesListFetched,
  } = branchesList;

  const [sortParams, setSortParams] = useState({
    sortParam: "name",
    sortOrder: "ASC",
  });

  const [filterParams, setFilterParams] = useState({
    name: "",
  });

  const [pageParams, setPageParams] = useState({ limit: 10, offset: 0 });

  const [filtersOpen, setFiltersOpen] = useState(false);

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
  };

  useEffect(() => {
    listBranches({ ...sortParams, ...filterParams, ...pageParams });
  }, [sortParams, filterParams, pageParams]);

  const formSubmit = async (values) => {
    setFilterParams(values);
  };

  const toggleSort = (sortParam) => {
    // ASC means true, desc means false
    const oldSortOrder = sortParams.sortOrder === "ASC" ? true : false;
    let newSortOrder =
      sortParams.sortParam === sortParam ? !oldSortOrder : true;
    setSortParams({
      sortParam,
      sortOrder: newSortOrder ? "ASC" : "DESC",
    });
  };

  return (
    <div>
      <Header></Header>
      <div className="flex flex-row">
        <Sidebar></Sidebar>
        <div className="flex-1">
          <BgImage></BgImage>
          <div className="text-left text-2xl leading-tight p-10 font-poppinsRegular">
            Branches
          </div>
          <div className="mx-auto m-0 p-10 relative">
            <div className="flex flex-row justify-between content-center justify-center place-content-center place-items-center self-center	justify-items-center	justify-self-center">
              <div>
                <div className="flex flex-row bg-white rounded-full py-2 px-6" onClick={toggleFiltersOpen}>
                  <div className="mr-4 text-blue text-lg">
                    <a href="#">Filters</a>
                  </div>
                  <img src={filterIcon} alt="filter" />
                </div>
                <div
                  className={`absolute t-0 l-0 w-96 p-5 min-h-48 bg-white rounded-md shadow ${
                    filtersOpen ? "block" : "hidden"
                  }`}
                >
                  <div className="flex flex-col">
                    <Formik
                      validateOnChange={false}
                      enableReinitialize={true}
                      initialValues={initialValues}
                      onSubmit={formSubmit}
                    >
                      <Form className="flex flex-col">
                        <div className="">
                          <Field
                            name="search"
                            type="text"
                            placeholder="Search by name"
                            className="rounded-full py-2 px-4 flex-1 appearance-none border border-whiteSmoke w-full bg-whiteSmoke text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                          ></Field>
                        </div>
                        <button
                          type="submit"
                          className="mt-5 w-full bg-blue rounded-sm text-white text-lg p-2 pl-4 text-center uppercase"
                        >
                          Filter
                        </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
              <Link to="/addBranch">
                <div className="flex flex-row flex-1 align-items align-middle items-center justify-center">
                  <div className="mr-4 text-taupeGray text-lg">
                    Add New Branch
                  </div>
                  <img
                    src={add}
                    className="rounded-full bg-blue w-10 h-10 p-3"
                    alt="Add button"
                  />
                </div>
              </Link>
            </div>
            <BranchList
              results={branchesListData}
              deleteBranch={deleteBranch}
              toggleSort={toggleSort}
            />
            <div className="flex flex-row items-end justify-end">
              <Paging total={total} setPageParams={setPageParams}></Paging>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  branchesList: getBranches(state),
});

const mapDispatchToProps = {
  listBranches,
  listCompanies,
  updateBranch,
  deleteBranch,
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchListPage);
