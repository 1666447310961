import _remove from 'lodash/remove';
import * as SceneActions from '../actions/scene';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  scenesList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total:0,
  },
  sceneDetails: {
    isFetching: false,
    isFetched: false,
    sceneData: null,
  },
};

const setSceneListFetching = fetching => state => ({
  ...state,
  scenesList: {
    ...state.scenesList,
    isFetching: fetching,
  },
});

const setSceneList = (state, { payload: { listData, total } }) => ({
  ...state,
  scenesList: {
    ...state.scenesList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setSceneFetching = fetching => state => ({
  ...state,
  sceneDetails: {
    ...state.sceneDetails,
    isFetching: fetching,
  },
});

const setScene = (state, { payload: { sceneData } }) => ({
  ...state,
  sceneDetails: {
    ...state.sceneDetails,
    sceneData,
    isFetching: false,
    isFetched: true,
  },
});

const setSceneDeleted = (state, { payload: { sceneId } }) => {
  const newListData = state.scenesList.listData;
  _remove(newListData, { id: sceneId });

  const newState = {
    ...state,
    scenesList: {
      ...state.scenesList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [SceneActions.LIST_SCENE_REQUEST]: setSceneListFetching(true),
  [SceneActions.LIST_SCENE_SUCCESS]: setSceneList,
  [SceneActions.LIST_SCENE_FAILURE]: setSceneListFetching(false),
  [SceneActions.FETCH_SCENE_REQUEST]: setSceneFetching(true),
  [SceneActions.FETCH_SCENE_SUCCESS]: setScene,
  [SceneActions.FETCH_SCENE_FAILURE]: setSceneFetching(false),
  [SceneActions.DELETE_SCENE_SUCCESS]: setSceneDeleted,
};

export default reducerWithActionMap(actionMap, initialState);


