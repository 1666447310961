import _remove from 'lodash/remove';
import * as CompanyActions from '../actions/company';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  companiesList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  companyDetails: {
    isFetching: false,
    isFetched: false,
    companyData: null,
  },
};

const setCompanyListFetching = fetching => state => ({
  ...state,
  companiesList: {
    ...state.companiesList,
    isFetching: fetching,
  },
});

const setCompanyList = (state, { payload: { listData, total } }) => ({
  ...state,
  companiesList: {
    ...state.companiesList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setCompanyFetching = fetching => state => ({
  ...state,
  companyDetails: {
    ...state.companyDetails,
    isFetching: fetching,
  },
});

const setCompany = (state, { payload: { companyData } }) => ({
  ...state,
  companyDetails: {
    ...state.companyDetails,
    companyData,
    isFetching: false,
    isFetched: true,
  },
});

const setCompanyDeleted = (state, { payload: { companyId } }) => {
  const newListData = state.companiesList.listData;
  _remove(newListData, { id: companyId });

  const newState = {
    ...state,
    companiesList: {
      ...state.companiesList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [CompanyActions.LIST_COMPANY_REQUEST]: setCompanyListFetching(true),
  [CompanyActions.LIST_COMPANY_SUCCESS]: setCompanyList,
  [CompanyActions.LIST_COMPANY_FAILURE]: setCompanyListFetching(false),
  [CompanyActions.DELETE_COMPANY_SUCCESS]: setCompanyDeleted,
  [CompanyActions.FETCH_COMPANY_REQUEST]: setCompanyFetching(true),
  [CompanyActions.FETCH_COMPANY_SUCCESS]: setCompany,
  [CompanyActions.FETCH_COMPANY_FAILURE]: setCompanyFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


