import React, {useEffect} from "react";
import { useCallback } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

const Login = () => {
  const location = useLocation();
  const currentLocationState = location.state || {
    from: { pathname: "/dashboard" },
  };

  const { keycloak } = useKeycloak();

  const login = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  if (keycloak?.authenticated) {
    return <Redirect to={currentLocationState?.from} />;
  }
  else {
    keycloak.login();
  }


  return (
    <></>
  );
};
export default Login;
