import getAxiosInstance from '../config/http';

export const CREATE_ROLE_REQUEST = 'ROLE@CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'ROLE@CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'ROLE@CREATE_ROLE_FAILURE';

export const createRole = roleData => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_ROLE_REQUEST,
  });
  try{
    const { data } = await http.post('/usermng/role', roleData);
    dispatch({
      type: CREATE_ROLE_SUCCESS,
      payload: {
        roleData: data,
      }
    });
    return data;
  }
  catch(e){
    dispatch({
      type: CREATE_ROLE_FAILURE
    });
    throw e;
  }
};

export const LIST_ROLE_REQUEST = 'ROLE@LIST_ROLE_REQUEST';
export const LIST_ROLE_SUCCESS = 'ROLE@LIST_ROLE_SUCCESS';
export const LIST_ROLE_FAILURE = 'ROLE@LIST_ROLE_FAILURE';

export const listRoles = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_ROLE_REQUEST,
  });
  try{
    const roleData = await http.get('/usermng/role/all', { params });
    dispatch({
      type: LIST_ROLE_SUCCESS,
      payload: {
        listData: roleData.data,
      }
    });
  }catch(e){
    dispatch({
      type: LIST_ROLE_FAILURE
    });
  };
};

export const FETCH_ROLE_REQUEST = 'ROLE@FETCH_ROLE_REQUEST';
export const FETCH_ROLE_SUCCESS = 'ROLE@FETCH_ROLE_SUCCESS';
export const FETCH_ROLE_FAILURE = 'ROLE@FETCH_ROLE_FAILURE';

export const fetchRole = roleId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_ROLE_REQUEST,
  });
  try{
    const roleData = await http.get(`/usermng/role/${roleId}`);
    dispatch({
      type: FETCH_ROLE_SUCCESS,
      payload: {
        roleData: roleData.data,
      }
    });
  } catch(e){
     dispatch({
       type: FETCH_ROLE_FAILURE,
     });
  }
};

export const LIST_ROLEBYNAME_REQUEST = 'ROLE@LIST_ROLEBYNAME_REQUEST';
export const LIST_ROLEBYNAME_SUCCESS = 'ROLE@LIST_ROLEBYNAME_SUCCESS';
export const LIST_ROLEBYNAME_FAILURE = 'ROLE@LIST_ROLEBYNAME_FAILURE';

export const listRolesByName = () => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_ROLEBYNAME_REQUEST,
  });
  try{
    const roleByNameData = await http.get('/usermng/role/get/admin');
    dispatch({
      type: LIST_ROLEBYNAME_SUCCESS,
      payload: {
        roleByNameData: roleByNameData.data,
      }
    });
  } catch(e){
    dispatch({
      type: LIST_ROLEBYNAME_FAILURE,
    });
  }
};

export const UPDATE_ROLE_REQUEST = 'ROLE@UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'ROLE@UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'ROLE@UPDATE_ROLE_FAILURE';

export const updateRole = (id, roleData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_ROLE_REQUEST,
  });
  try{
    const data = await http.put(`/usermng/role/${id}`, roleData);
    dispatch({
      type: UPDATE_ROLE_SUCCESS,
      payload: {
        roleData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_ROLE_FAILURE,
    });
  }
};

export const DELETE_ROLE_REQUEST = 'ROLE@DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'ROLE@DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'ROLE@DELETE_ROLE_FAILURE';

export const deleteRole = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_ROLE_REQUEST,
  });
  try{
    const data = await http.delete(`/usermng/role/${id}`);
    dispatch({
      type: DELETE_ROLE_SUCCESS,
      payload: {
        roleId: id
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_ROLE_FAILURE,
    });
  }
};
