import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

// Assets
import edit from "../../img/edit.svg";
import X from "../../img/x.svg";
import sort from "../../img/blue-icons/sort.svg";
import { generateTreeStructure } from "../../utils/companies";

const CompanyList = ({
  results,
  companies,
  deleteCompany,
  toggleSort,
  ...props
}) => {
  const [openedCompanies, setOpenedCompanies] = useState({});
  const treeStructure = useMemo(() => {
    return generateTreeStructure(results, 0, [], true);
  }, [results]);

  const toggleCompanyOpen = useCallback(
    (companyId) => {
      const openedValue = !(openedCompanies[companyId] || false);
      setOpenedCompanies({
        ...openedCompanies,
        [companyId]: openedValue,
      });
    },
    [openedCompanies]
  );

  const deleteCompanyWithConfirmation = useCallback((companyId) => {
    const sure = window.confirm("Are you sure?");
    if (sure) {
      deleteCompany(companyId);
    }
  });
  return (
    <>
      <div className="shadow rounded-md bg-white mt-10 p-8">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                scope="col"
                className="px-1 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              ></th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Company Name
                <button
                  onClick={() => toggleSort("name")}
                  className="focus:outline-none"
                >
                  <img
                    src={sort}
                    className="ml-5 inline rounded-full bg-whiteSmoke w-10 h-10 p-3"
                    alt="Sort button"
                  />
                </button>
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Address
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Phone
              </th>
              {/* <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Path
              </th> */}
              {/* <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                VAT
              </th>
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Email
              </th>
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Phone Number
              </th> */}
              <th
                scope="col-span-2"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {treeStructure
              .filter((company) => {
                if (!company.parents || company.parents.length === 0) {
                  return true;
                }
                let allParentsOpen = true;
                for (let i = 0; i < company.parents.length; ++i) {
                  if (!openedCompanies[company.parents[i].id]) {
                    allParentsOpen = false;
                    break;
                  }
                }
                return allParentsOpen;
              })
              .map((company, idx) => {
                return (
                  <tr {...props}>
                    <td className="px-2 py-5 border-b border-gray-200 text-sm">
                      {company.subGroups && company.subGroups.length > 0 && (
                        <button onClick={() => toggleCompanyOpen(company.id)}>
                          {openedCompanies[company.id] &&
                          openedCompanies[company.id] === true
                            ? "▼"
                            : "►"}
                        </button>
                      )}
                      {/* <p className="text-gray-900 whitespace-no-wrap">
                      {idx + 1}
                    </p> */}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {idx + 1}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {company.structuredName}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {_.get(company, "attributes.email", []).join(", ")}
                        {/* {company.subGroups &&
                        company.subGroups.map((subgroup, idx) => {
                          return (
                            <p>
                              {subgroup && subgroup.name !== "branches"
                                ? subgroup.name
                                : ""}
                            </p>
                          );
                        })} */}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {_.get(company, "attributes.address", []).join(", ")}
                        {/* {company.subGroups &&
                        company.subGroups.map((subgroup, idx) => {
                          return (
                            <p>
                              {subgroup && subgroup.name !== "branches"
                                ? subgroup.name
                                : ""}
                            </p>
                          );
                        })} */}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {_.get(company, "attributes.telNumber", []).join(", ")}
                        {/* {company.subGroups &&
                        company.subGroups.map((subgroup, idx) => {
                          return (
                            <p>
                              {subgroup && subgroup.name !== "branches"
                                ? subgroup.name
                                : ""}
                            </p>
                          );
                        })} */}
                      </p>
                    </td>
                    {/* <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {company.path}
                    </p>
                  </td> */}
                    {/* <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {company.VAT}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {company.email}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {company.telNumber}
                    </p>
                  </td> */}
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      {company.name !== "branches" && company.name !== "root" &&  (
                        <div className="flex flex-row">
                          <div>
                            {company.path.indexOf("/branches") >= 0 ? (
                              <Link to={`/editBranch/${company.id}`}>
                                <img
                                  src={edit}
                                  className="bg-lightBlue rounded p-2 w-8 mr-5"
                                  alt="Edit button"
                                />
                              </Link>
                            ) : (
                              <Link to={`/editCompany/${company.id}`}>
                                <img
                                  src={edit}
                                  className="bg-lightBlue rounded p-2 w-8 mr-5"
                                  alt="Edit button"
                                />
                              </Link>
                            )}
                          </div>
                          <button onClick={() => deleteCompanyWithConfirmation(company.id)}>
                            <img
                              src={X}
                              alt="Delete button"
                              className="rounded border-2 border-gray-300 p-2 w-8 mr-5"
                            />
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CompanyList;
