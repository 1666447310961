import _ from "lodash";

export const roleHierarchy = ["seller", "dealer", "buyer"];

export const generateParentRoles = (role, includeSelfRole = false, maxCount = 1000) => {
  const idx = _.indexOf(roleHierarchy, role);
  const endIdx = includeSelfRole ? (idx + 1) : idx;
  return roleHierarchy.slice(Math.max(endIdx - maxCount, 0), endIdx);
};

export const generateChildRoles = (role, includeSelfRole = false, maxCount = 1000) => {
  const idx = _.indexOf(roleHierarchy, role);
  const startIdx = includeSelfRole ? (idx + 1) : idx;
  return roleHierarchy.slice(startIdx, Math.min(startIdx + maxCount, roleHierarchy));
};

export const generateTreeStructure = (
  list,
  depth,
  parentList = [],
  includeBranches = false,
  rolesToInclude = roleHierarchy
) => {
  let res = [];
  for (let i = 0; i < list.length; ++i) {
    if (
      (!includeBranches && list[i].name === "branches") ||
      _.intersection(rolesToInclude, list[i].realmRoles).length === 0
    ) {
      continue;
    }
    res.push({
      ...list[i],
      // subGroups: null,
      parents: parentList,
      structuredName: `${_.repeat(" ", depth)}${depth > 0 ? "└" : ""} ${
        list[i].name
      }`,
    });
    const childCompanies = includeBranches
      ? list[i].subGroups
      : _.filter(list[i].subGroups, (x) => x.name !== "branches");
    if (childCompanies.length > 0) {
      const recRes = generateTreeStructure(
        childCompanies,
        depth + 1,
        parentList.concat([list[i]]),
        includeBranches
      );
      res = res.concat(recRes);
    }
  }
  return res;
};

export const findGroupByPath = (groupList, path) => {
  for (let i = 0; i < groupList.length; ++i) {
    if (path === groupList[i].path) {
      return groupList[i];
    } else if (path.startsWith(groupList[i].path)) {
      return findGroupByPath(groupList[i].subGroups, path);
    }
  }
};

export const getCompanyBranchTitle = (branch) => {
  const isBranch = branch.path.includes("/branches/");
  let name = branch.name;
  if (isBranch) {
    const spl = branch.path.split("/branches/");
    const pathBeforeBranches = spl[0].split("/");
    name = `${pathBeforeBranches[pathBeforeBranches.length - 1]}/${spl[1]}`;
  }
  return name;
};

export const generateChildCompanyTreeStructure = (
  companyList,
  userGroup,
  includeBranches = false
) => {
  let branchIdx = userGroup.indexOf("/branches");
  let childrenPath = userGroup.substring(0, branchIdx);
  const parentGroup = findGroupByPath(
    companyList,
    childrenPath,
    includeBranches
  );
  if (!parentGroup) {
    return [];
  }
  return generateTreeStructure([parentGroup], 0, [], includeBranches);
};

export const generateBranchesTreeStructure = (companyList, branchesGroup) => {
  const parentGroup = findGroupByPath(companyList, branchesGroup, true);
  return generateTreeStructure(parentGroup.subGroups);
};
