import React from "react";
import { Link } from "react-router-dom";
import edit from "../../img/edit.svg";
import X from "../../img/x.svg";
import sort from "../../img/blue-icons/sort.svg";

const UserList = ({ results, users, deleteUser, ...props }) => {
  return (
    <>
      <div className="shadow rounded-md bg-white mt-10 p-8">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                First & last name
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Email
              </th>
              {/* <th
                scope="col"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Role
              </th> */}
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Username
              </th>
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Enabled
              </th>
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Branch
              </th>
              <th
                scope="col-span-2"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Phone number
              </th>
              <th
                scope="col-span"
                className="px-5 py-3 border-b border-t border-gray-200 text-black text-left text-lg font-normal"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {results.map((user, idx) => {
              return (
                <tr {...props}>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {idx + 1}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {user.firstName} {user.lastName}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {user.email}
                    </p>
                  </td>
                  {/* <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {user.role.name}
                    </p>
                  </td> */}
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {user.username}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {user.enabled === true ? "Active" : "Inactive"}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {/* {user.branch.name} */}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {user.telNumber}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 text-sm">
                    <div className="flex flex-row">
                      <div>
                        <Link to={`/editUser/${user.id}`}>
                          <img
                            src={edit}
                            className="bg-lightBlue rounded p-2 w-8 mr-5"
                            alt="Edit button"
                          />
                        </Link>
                      </div>
                      <button onClick={() => deleteUser(user.id)}>
                        <img
                          src={X}
                          alt="Delete button"
                          className="rounded border-2 border-gray-300 p-2 w-8 mr-5"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserList;
