import _remove from 'lodash/remove';
import * as AvatarActions from '../actions/avatar';
import reducerWithActionMap from '../utils/reducerWithActionMap';

const initialState = {
  isAuthenticated: false,
  avatarsList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  avatarDetails: {
    isFetching: false,
    isFetched: false,
    avatarData: null,
  },
};

const setAvatarListFetching = fetching => state => ({
  ...state,
  avatarsList: {
    ...state.avatarsList,
    isFetching: fetching,
  },
});

const setAvatarList = (state, { payload: { listData, total } }) => ({
  ...state,
  avatarsList: {
    ...state.avatarsList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setAvatarFetching = fetching => state => ({
  ...state,
  avatarDetails: {
    ...state.avatarDetails,
    isFetching: fetching,
  },
});

const setAvatar = (state, { payload: { avatarData } }) => ({
  ...state,
  avatarDetails: {
    ...state.avatarDetails,
    avatarData,
    isFetching: false,
    isFetched: true,
  },
});

const setAvatarDeleted = (state, { payload: { avatarId } }) => {
  const newListData = state.avatarsList.listData;
  _remove(newListData, { id: avatarId });

  const newState = {
    ...state,
    avatarsList: {
      ...state.avatarsList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [AvatarActions.LIST_AVATAR_REQUEST]: setAvatarListFetching(true),
  [AvatarActions.LIST_AVATAR_SUCCESS]: setAvatarList,
  [AvatarActions.LIST_AVATAR_FAILURE]: setAvatarListFetching(false),
  [AvatarActions.FETCH_AVATAR_REQUEST]: setAvatarFetching(true),
  [AvatarActions.FETCH_AVATAR_SUCCESS]: setAvatar,
  [AvatarActions.FETCH_AVATAR_FAILURE]: setAvatarFetching(false),
  [AvatarActions.DELETE_AVATAR_SUCCESS]: setAvatarDeleted,
};

export default reducerWithActionMap(actionMap, initialState);


