import _remove from "lodash/remove";
import * as RoleActions from "../actions/role";
import reducerWithActionMap from "../utils/reducerWithActionMap";

const initialState = {
  isAuthenticated: false,
  rolesList: {
    isFetching: false,
    isFetched: false,
    listData: [],
  },
  roleDetails: {
    isFetching: false,
    isFetched: false,
    roleData: null,
  },
};

const setRoleListFetching = (fetching) => (state) => ({
  ...state,
  rolesList: {
    ...state.rolesList,
    isFetching: fetching,
  },
});

const setRoleList = (state, { payload: { listData } }) => ({
  ...state,
  rolesList: {
    ...state.rolesList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const setRoleFetching = (fetching) => (state) => ({
  ...state,
  roleDetails: {
    ...state.roleDetails,
    isFetching: fetching,
  },
});

const setRole = (state, { payload: { roleData } }) => ({
  ...state,
  roleDetails: {
    ...state.roleDetails,
    roleData,
    isFetching: false,
    isFetched: true,
  },
});

const setRoleDeleted = (state, { payload: { roleId } }) => {
  const newListData = state.rolesList.listData;
  _remove(newListData, { id: roleId });

  const newState = {
    ...state,
    rolesList: {
      ...state.rolesList,
      listData: newListData,
    },
  };
  return newState;
};

const setRoleByNameListFetching = (fetching) => (state) => ({
  ...state,
  rolesByNameList: {
    ...state.rolesList,
    isFetching: fetching,
  },
});

const setRoleByNameList = (state, { payload: { listData } }) => ({
  ...state,
  rolesByNameList: {
    ...state.rolesByNameList,
    listData,
    isFetching: false,
    isFetched: true,
  },
});

const actionMap = {
  [RoleActions.LIST_ROLE_REQUEST]: setRoleListFetching(true),
  [RoleActions.LIST_ROLE_SUCCESS]: setRoleList,
  [RoleActions.LIST_ROLE_FAILURE]: setRoleListFetching(false),
  [RoleActions.FETCH_ROLE_REQUEST]: setRoleFetching(true),
  [RoleActions.FETCH_ROLE_FAILURE]: setRoleFetching(false),
  [RoleActions.FETCH_ROLE_SUCCESS]: setRole,
  [RoleActions.FETCH_ROLE_FAILURE]: setRoleFetching(false),
  [RoleActions.LIST_ROLEBYNAME_REQUEST]: setRoleFetching(),
  [RoleActions.LIST_ROLEBYNAME_SUCCESS]: setRoleFetching(),
  [RoleActions.LIST_ROLEBYNAME_FAILURE]: setRoleFetching(),
  [RoleActions.DELETE_ROLE_SUCCESS]: setRoleDeleted,
  [RoleActions.LIST_ROLEBYNAME_REQUEST]: setRoleByNameListFetching(true),
  [RoleActions.LIST_ROLEBYNAME_SUCCESS]: setRoleByNameList,
  [RoleActions.LIST_ROLEBYNAME_FAILURE]: setRoleByNameListFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);
