import getAxiosInstance from '../config/http';

export const CREATE_GARMENT_REQUEST = 'GARMENT@CREATE_GARMENT_REQUEST';
export const CREATE_GARMENT_SUCCESS = 'GARMENT@CREATE_GARMENT_SUCCESS';
export const CREATE_GARMENT_FAILURE = 'GARMENT@CREATE_GARMENT_FAILURE';

export const createGarment = garmentData => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_GARMENT_REQUEST,
  });
  try{
    const { data } = await http.post('/productmng/garment', garmentData);
    dispatch({
      type: CREATE_GARMENT_SUCCESS,
      payload: {
        garmentData: data,
      },
    });
    return data;
  }
  catch(e){
    dispatch({
      type: CREATE_GARMENT_FAILURE,
    });
    throw e;
  }
};

export const UPLOAD_GARMENT_REQUEST = 'GARMENT@UPLOAD_GARMENT_REQUEST';
export const UPLOAD_GARMENT_SUCCESS = 'GARMENT@UPLOAD_GARMENT_SUCCESS';
export const UPLOAD_GARMENT_FAILURE = 'GARMENT@UPLOAD_GARMENT_FAILURE';

export const uploadGarment = (id, file) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPLOAD_GARMENT_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("avatar", file);
    const { data } = await http.post(
      `/productmng/garment/${id}/avatar`,
      formData
    );
    dispatch({
      type: UPLOAD_GARMENT_SUCCESS,
      // payload: {
      //   garmentData: data,
      // },
    });
    return data;
  }
  catch(e){
    dispatch({
      type: UPLOAD_GARMENT_FAILURE,
    });
    throw e;
  }
};


export const LIST_GARMENT_REQUEST = 'GARMENT@LIST_GARMENT_REQUEST';
export const LIST_GARMENT_SUCCESS = 'GARMENT@LIST_GARMENT_SUCCESS';
export const LIST_GARMENT_FAILURE = 'GARMENT@LIST_GARMENT_FAILURE';

export const listGarments = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_GARMENT_REQUEST,
  });
  try{
    const garmentData = await http.get('/productmng/garment/list/all', {params});
    dispatch({
      type: LIST_GARMENT_SUCCESS,
      payload: {
        listData: garmentData.data.results,
        total: garmentData.data.total,
      }
    });
  }catch(e){
    dispatch({
      type: LIST_GARMENT_FAILURE,
    });
  }
};

export const LIST_GARMENTS_ACTIVE_STATUS_REQUEST = 'GARMENT@LIST_GARMENTS_ACTIVE_STATUS_REQUEST';
export const LIST_GARMENTS_ACTIVE_STATUS_SUCCESS = 'GARMENT@LIST_GARMENTS_ACTIVE_STATUS_SUCCESS';
export const LIST_GARMENTS_ACTIVE_STATUS_FAILURE = 'GARMENT@LIST_GARMENTS_ACTIVE_STATUS_FAILURE';

export const listGarmentsByActiveStatus = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_GARMENTS_ACTIVE_STATUS_REQUEST,
  });
  try{
    const garmentData = await http.get('/productmng/garment/status/all', {params});
    dispatch({
      type: LIST_GARMENTS_ACTIVE_STATUS_REQUEST,
      payload: {
        listData: garmentData.data.results,
        count: garmentData.data.count,
      }
    });
  }catch(e){
    dispatch({
      type: LIST_GARMENTS_ACTIVE_STATUS_FAILURE,
    });
  }
};

export const FETCH_GARMENT_REQUEST = 'GARMENT@FETCH_GARMENT_REQUEST';
export const FETCH_GARMENT_SUCCESS = 'GARMENT@FETCH_GARMENT_SUCCESS';
export const FETCH_GARMENT_FAILURE = 'GARMENT@FETCH_GARMENT_FAILURE';

export const fetchGarment = garmentId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_GARMENT_FAILURE,
  });
  try{
    const garmentData = await http.get(`/productmng/garment/${garmentId}`);
    dispatch({
      type: FETCH_GARMENT_SUCCESS,
      payload: {
        garmentData: garmentData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_GARMENT_FAILURE,
    });
  };
};

export const UPDATE_GARMENT_REQUEST = 'GARMENT@UPDATE_GARMENT_REQUEST';
export const UPDATE_GARMENT_SUCCESS = 'GARMENT@UPDATE_GARMENT_SUCCESS';
export const UPDATE_GARMENT_FAILURE = 'GARMENT@UPDATE_GARMENT_FAILURE';

export const updateGarment = (id, garmentData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_GARMENT_REQUEST,
  });
  try{
    const { data } = await http.put(`/productmng/garment/${id}`, garmentData);
    dispatch({
      type: UPDATE_GARMENT_SUCCESS,
      payload: {
        garmentData: data,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: UPDATE_GARMENT_FAILURE,
    });
    throw e;
  };
};

export const DELETE_GARMENT_REQUEST = 'GARMENT@DELETE_GARMENT_REQUEST';
export const DELETE_GARMENT_SUCCESS = 'GARMENT@DELETE_GARMENT_SUCCESS';
export const DELETE_GARMENT_FAILURE = 'GARMENT@DELETE_GARMENT_FAILURE';

export const deleteGarment = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_GARMENT_REQUEST,
  });
  try{
    const data = await http.delete(`/productmng/garment/${id}`);
    dispatch({
      type: DELETE_GARMENT_SUCCESS,
      payload: {
        garmentId: id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_GARMENT_FAILURE,
    });
  }
};
